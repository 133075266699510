import _ from "lodash"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useReactToPrint } from 'react-to-print'
import { DateRangeSelector, RadioSwitch } from "../components"
import { Cases, ClientOnboarding, EndedCases, PlatformCollaborations, Surveys, UserAverage, Users } from "../components/analytics"
import { products, userDetailTitles, userTypes, graphTypes, platformActionLabels, platformNotesAndUploadsLabels, dataType, frequencyTypes, coachUserStatusLabels } from "../config/constants"
import useAnalytics, { interfaces } from "../utils/hooks/useAnalytics"
import useSettings from "../utils/hooks/useSettings"
import HubCaseTasks from "../components/analytics/HubCaseTasks"
import { getLastMonthDates, monthsDifference } from "../utils/helper"
import { ReactComponent as PrintIcon } from "../assets/svg/print.svg"
import IconButton from "../components/analytics/IconButton"
import LoadingWrapper from "../components/generic/LoadingWrapper"
import { LineChart } from "../components/charts"
import categories from "../config/categories.json"
import { getSubcategory } from "../config/content"

const productSettings = {
    ALL: [{ label: 'Platform', value: products.PLATFORM }, { label: 'Hub', value: products.HUB }],
    PLATFORM: [{ label: 'Platform', value: products.PLATFORM }],
    HUB: [{ label: 'Hub', value: products.HUB }]
}

const userSettings = {
    ALL: [{ label: 'Cliënt', value: userTypes.CLIENT }, { label: 'Coach', value: userTypes.COACH }],
    COACH: [{ label: 'Coach', value: userTypes.COACH }],
    CLIENT: [{ label: 'Cliënt', value: userTypes.CLIENT }]
}

const graphSettings = {
    ALL: [{ label: 'Detail', value: graphTypes.DETAIL }, { label: 'Totaal', value: graphTypes.TOTAL }],
}

const getAvailableFrequencies = (startDate, endDate) => {
    let frequencies = []
    const start = new Date(startDate), end = new Date(endDate)
    const difference = end.getTime() - start.getTime()

    if (difference / (1000 * 3600 * 24) > 14) {
        if (difference / (1000 * 3600 * 24 * 7) > 5) {
            const monthDiff = monthsDifference(start, end)
            if (monthDiff > 9) {
                // frequencies = monthDiff > 32
                //     ? [{ label: 'Jaren', value: frequencyTypes.YEARS }]
                //     : [{ label: 'Jaren', value: frequencyTypes.YEARS }, { label: 'Maanden', value: frequencyTypes.MONTHS }]
                frequencies = [{ label: 'Maanden', value: frequencyTypes.MONTHS }]
            } else {
                // 9 months or less, show months and weeks
                frequencies = [{ label: 'Weken', value: frequencyTypes.WEEKS }, { label: 'Maanden', value: frequencyTypes.MONTHS }]
            }
        } else {
            // 5 weeks or less, show weeks and days
            frequencies = [{ label: 'Dagen', value: frequencyTypes.DAYS }, { label: 'Weken', value: frequencyTypes.WEEKS }]
        }
    } else {
        // 14 days or less, show days only
        frequencies = [{ label: 'Dagen', value: frequencyTypes.DAYS }]
    }

    return frequencies
}

const getLayout = (id, settings, getData) => {
    const usersInterface = settings.filterProduct === products.HUB ? interfaces.USER_STATUS : interfaces.USER_STATUS

    const layouts = {
        users: {
            title: userDetailTitles[settings.filterProduct][settings.filterUser] || userDetailTitles[settings.filterProduct],
            filterProduct: productSettings.ALL,
            filterUser: settings.filterProduct !== products.HUB && userSettings.ALL,
            filterGraph: graphSettings.ALL,
            graph: <Users
                labels={settings.filterProduct === products.PLATFORM && settings.filterUser === userTypes.COACH && coachUserStatusLabels}
                data={getData(dataType.RANGE, usersInterface, { filters: [{ field: 'product_name', value: settings.filterProduct }] })}
                userType={settings.filterUser}
                settings={settings}
                isDetailPage
            />,
            interface: usersInterface,
            filters: [{ field: 'product_name', value: settings.filterProduct }]
        },
        stressForm: {
            title: 'Vragenlijsten financiële stressmeting',
            filterUser: userSettings.ALL,
            graph: <Surveys data={getData(dataType.RANGE, interfaces.QUESTIONAIRE)} isDetailPage userType={settings.filterUser} />,
            interface: interfaces.QUESTIONAIRE
        },
        platformClientOnboarding: {
            title: 'Stappenplan',
            graph: <ClientOnboarding data={getData(dataType.RANGE, interfaces.ONBOARDING)} isDetailPage />,
            interface: interfaces.ONBOARDING
        },
        hubEndedCases: { 
            title: 'Beëindigde trajecten',
            graph: <EndedCases data={getData(dataType.RANGE, interfaces.CASES)} isDetailPage />,
            interface: interfaces.CASES
        },
        platformClientUserAverage: {
            title: 'Gemiddeldes per Platform cliënt',
            graph: <UserAverage data={getData(dataType.RANGE, interfaces.BUDGET_STATS)} setting={dataType.RANGE} isDetailPage />,
            interface: interfaces.BUDGET_STATS
        },
        platformCollaborations: {
            title: 'Aantal cliënten per',
            graph: <PlatformCollaborations data={getData(dataType.RANGE, interfaces.PLATFORM_COLLABORATIONS)} />,
            interface: interfaces.PLATFORM_COLLABORATIONS
        },
        hubCases: {
            title: 'Trajecten',
            graph: <Cases data={getData(dataType.RANGE, interfaces.CASES)} />,
            interface: interfaces.CASES
        },
        hubCaseTasks: {
            title: 'Voltooide taken per',
            graph: <HubCaseTasks data={getData(dataType.RANGE, interfaces.CASES)} />,
            interface: interfaces.CASES
        },
        subcategory: {
            title: `Gemiddelden voor subcategorie: ${categories[`subcategory_${getSubcategory(settings.id)}`]}`,
            graph: <LineChart
            yLabel="€"
                data={[{
                    name: categories[`subcategory_${getSubcategory(settings.id)}`],
                    data: getData(dataType.RANGE, interfaces.BUDGET_AVERAGES, { filters: [{ field: 'category_sub', value: settings.id }] })?.map(item => [item.timestamp, item.avg_sum / 100])
                }]}
                height={500}
            />,
            interface: interfaces.BUDGET_AVERAGES,
            filters: [{ field: 'category_sub', value: settings.id }]
        },

        // will implement later
        platformNotesAndUploads: { filterUser: userSettings.ALL, title: 'Notities en uploads', labels: platformNotesAndUploadsLabels },
        platformActions: { title: 'Acties', labels: platformActionLabels },
    }

    return layouts[id] 
}

const defaultSettings = {
    filterProduct: products.PLATFORM,
    filterUser: products.CLIENT,
    filterGraph: graphTypes.DETAIL,
}

const ArrowIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill="currentColor" fillRule="evenodd" d="M.8 7.2h12.5L7.4 1.3a.8.8 0 0 1 0-1c.4-.4.9-.4 1.2-.1l7.2 7.2c.3.3.3.9 0 1.2l-7.2 7.2c-.3.3-.8.3-1.1 0a.8.8 0 0 1 0-1.1l5.8-5.9H.8A.8.8 0 0 1 0 8c0-.4.4-.8.8-.8Z"/></svg>
const CalendarIcon = () => <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1.5" stroke="currentColor" className="h-5" viewBox="2.25 2.25 19.5 19.5"><path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"></path></svg>

const PDFLabel = ({label, children}) => <div className="flex gap-4 items-center text-primary font-bold">{label}: {children}</div>

const AnalyticDetail = () => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const navigate = useNavigate()
    const { id } = useParams()
    const [settings, setSettings] = useSettings(defaultSettings)
    const [, { fetchData, getData, getLoading }] = useAnalytics()
    const print = useReactToPrint({ content: () => printContent.current })

    const printContent = useRef()

    const { filterProduct, filterUser, filterGraph, filterFrequency, startDate, endDate } = settings
    const layout = getLayout(id, settings, getData)

    const set = (key, value) => setSettings({ ...settings, [key]: value })
    
    const frequencies = getAvailableFrequencies(startDate, endDate)
    const frequency = _.some(frequencies, freq => freq.value === filterFrequency) ? filterFrequency : frequencies[0].value

    useEffect(() => {
        // defaults to last month
        if (!startDate && !endDate) {
            setSettings({ ...settings, ...getLastMonthDates() })
        }

        setIsFirstLoad(false)
        fetchData(layout.interface, { startDate, endDate, frequency, filters: layout.filters })
    }, [settings]) // eslint-disable-line


    return (
        <>
            <div className="flex flex-col gap-8 p-9 max-w-[1372px] mx-auto text-primary">
                <div className="flex gap-4">
                    <div onClick={() => navigate(-1)} className="
                        w-[40px] h-[40px] border-2 border-primary rounded-full flex justify-center items-center rotate-180
                        hover:bg-primary hover:text-neutral-20 cursor-pointer transition-colors
                    ">
                        <ArrowIcon />
                    </div>
                    <h1 className="mr-auto text-primary text-[1.75rem] font-ALSSchlangeslabBold">{layout.title}</h1>
                </div>

                    <div className="flex gap-4 -mt-2 h-fit">
                        {layout.filterProduct && <RadioSwitch selected={filterProduct} setSelected={v => set('filterProduct',v)} items={layout.filterProduct} />}
                        {layout.filterUser && <RadioSwitch selected={filterUser} setSelected={v => set('filterUser',v)} items={layout.filterUser} />}
                        {layout.filterGraph && <RadioSwitch selected={filterGraph} setSelected={v => set('filterGraph',v)} items={layout.filterGraph} />}
                        <RadioSwitch selected={frequency} setSelected={v => set('filterFrequency',v)} items={frequencies} />

                        <div className="ml-auto">
                            <DateRangeSelector
                                start={startDate}
                                end={endDate}
                                icon={<CalendarIcon/>}
                                onChange={(startDate, endDate) => setSettings(v => {
                                    const data = { ...v }
                                    startDate ? data.startDate = startDate : delete data.startDate
                                    endDate ? data.endDate = endDate : delete data.endDate
                                    return data
                                })}
                            />
                        </div>
                        <div>
                            <IconButton
                                onClick={print}
                                icon={<PrintIcon />}
                                label="Print"
                            />
                        </div>
                    </div>

                <div className="relative">
                    <LoadingWrapper containerClass="flex flex-col" isLoading={isFirstLoad || getLoading(dataType.RANGE, layout.interface, { filters: layout.filters })} preRenderChildren>
                        {/* Print logic/layout */}
                        <div ref={printContent} className="print:p-8">
                            <div className="flex flex-col gap-4 invisible h-0 print:visible print:h-auto">                        
                                <h1 className="mr-auto text-primary text-[1.75rem] font-ALSSchlangeslabBold">{layout.title}</h1>
                                <div className="flex gap-8">
                                    {layout.filterProduct && <PDFLabel label="Product">
                                        <RadioSwitch selected={filterProduct} items={layout.filterProduct} />
                                    </PDFLabel>}
                                    {layout.filterUser && <PDFLabel label="Gebruikers type">
                                        <RadioSwitch selected={filterUser} items={layout.filterUser} />
                                    </PDFLabel>}
                                    {startDate && endDate && <PDFLabel label="Data valt binnen">
                                        <div className="flex justify-center items-center gap-4 font-bold text-[14px] border text-primary border-neutral-60 rounded-lg p-[12px_24px_12px_18px]">
                                            <CalendarIcon/>
                                            {new Date(startDate).toLocaleDateString('nl', { year: 'numeric', month: 'numeric', day: 'numeric' })} t/m {new Date(endDate).toLocaleDateString('nl', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                                        </div>
                                    </PDFLabel>}
                                </div>
                            </div>
                            { layout.graph }
                        </div>
                    </LoadingWrapper>
                </div>
            </div>


            {/* Print styles */}
            <style type="text/css" media="print">{'@page { size: landscape }'}</style>
        </>
    )
}

export default AnalyticDetail
