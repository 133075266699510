import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { cleanObject } from '../helper'

const useSettings = (defaultSettings = {}, identifier) => {
    const location = useLocation()
    const [, setSearchParams] = useSearchParams()
    const id = identifier || `settings-${location.pathname}`

    const get = () => {
        const storedSettings = localStorage.getItem(id)
        const parsedSettings = storedSettings ? JSON.parse(storedSettings) : {}
        const paramsSettings = Object.fromEntries(new URLSearchParams(window.location.search))
        return { ...defaultSettings, ...parsedSettings, ...paramsSettings }
    }
    const [settings, setSettings] = useState(get())

    useEffect(() => {
        if (settings) {
            setSearchParams(new URLSearchParams(cleanObject(settings)), { replace: true })
            localStorage.setItem(id, JSON.stringify(settings))
        } else {
            const foundSettings = get()
            setSettings(foundSettings)
        }
    }, [settings]) // eslint-disable-line react-hooks/exhaustive-deps

    return [settings, setSettings]
}

export default useSettings
