import _ from 'lodash'
import { useContext, useEffect, useState } from "react"
import { ContentContext } from '../ContentContext'

const parseFormPayload = article => ({
    ..._.pick(article, ['id', 'title', 'sub_title', 'label', 'url']),
    image: _.pickBy({
        url: _.get(article, 'image_url'),
        color: _.get(article, 'image_color'),
    })
})

const useArticles = () => {
    const { articles, dispatch, apiCall } = useContext(ContentContext)
    const [loading, setIsLoading] = useState()

    const getArticles = async () => {
        setIsLoading(true)
        try {
            const { data } = await apiCall(
                `/articles`,
                { },
                'get'
            )

            dispatch({
                articles: data,
                type: 'ARTICLE_LIST_FETCH_SUCCESS',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const createArticle = async article => {
        setIsLoading(true)
        try {
            const parsedArticle = parseFormPayload(article)
            const { data } = await apiCall('/articles', {
                body: parsedArticle}, 'post', true)

            if (_.get(data, 'id')) {
                dispatch({
                    article: {
                        id: _.get(data, 'id'),
                        ...parsedArticle
                    },
                    type: 'ARTICLE_CREATE_SUCCESS',
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    const updateArticle = async (article, id) => {
        setIsLoading(true)
        try {
            const parsedArticle = parseFormPayload(article)
            await apiCall(
                `/articles/${id}`,
                { body: parsedArticle },
                'put',
                true
            )

            dispatch({
                article: {
                    ...parsedArticle,
                    id
                },
                id,
                type: 'ARTICLE_UPDATE_SUCCESS',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const deleteArticle = async id => {
        setIsLoading(true)
        try {
            await apiCall(`/articles/${id}`, {body: {}}, 'delete', true)

            dispatch({
                id,
                type: 'ARTICLE_DELETE_SUCCESS',
            })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!articles) {
            getArticles()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articles])

    return [articles, { createArticle, updateArticle, deleteArticle }, loading]
}

export default useArticles
