import { ConnectRoute, useConnectState } from '@plinkrlabs/connect-hooks'
import { BrowserRouter } from 'react-router-dom'
import AuthenticatedApp from './AuthenticatedApp'

const App = () => {
    const { loggedIn } = useConnectState()

    return (
        <BrowserRouter>
            {loggedIn ? <AuthenticatedApp /> : <ConnectRoute env={process.env.REACT_APP_HOST_ENV} />}
        </BrowserRouter>
    )
}

export default App
