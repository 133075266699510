import { MessageDisplay } from "@plinkrlabs/plinkr-ui"
import { dataType } from "../config/constants"
import IconButton from "./analytics/IconButton"
import LoadingWrapper from "./generic/LoadingWrapper"
import RadioSwitch from "./RadioSwitch"

const PieChartIcon = () =>
    <svg width="22" height="22" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2812 11.25H21.3281C21.1371 9.21667 20.2821 7.45417 18.763 5.9625C17.2439 4.47083 15.4166 3.65833 13.2812 3.525V11.25ZM11.7187 20.475V3.525C9.44442 3.70833 7.53036 4.61667 5.97654 6.25C4.42272 7.88333 3.64581 9.8 3.64581 12C3.64581 14.2 4.42272 16.1167 5.97654 17.75C7.53036 19.3833 9.44442 20.2917 11.7187 20.475ZM13.2812 20.475C15.4166 20.3417 17.2439 19.5292 18.763 18.0375C20.2821 16.5458 21.1371 14.7833 21.3281 12.75H13.2812V20.475ZM12.5 22C11.059 22 9.70484 21.7375 8.43748 21.2125C7.17012 20.6875 6.06769 19.975 5.13019 19.075C4.19269 18.175 3.4505 17.1167 2.90363 15.9C2.35675 14.6833 2.08331 13.3833 2.08331 12C2.08331 10.6167 2.35675 9.31667 2.90363 8.1C3.4505 6.88333 4.19269 5.825 5.13019 4.925C6.06769 4.025 7.17012 3.3125 8.43748 2.7875C9.70484 2.2625 11.059 2 12.5 2C13.941 2 15.2951 2.2625 16.5625 2.7875C17.8298 3.3125 18.9323 4.025 19.8698 4.925C20.8073 5.825 21.5495 6.88333 22.0963 8.1C22.6432 9.31667 22.9166 10.6167 22.9166 12C22.9166 13.3833 22.6432 14.6833 22.0963 15.9C21.5495 17.1167 20.8073 18.175 19.8698 19.075C18.9323 19.975 17.8298 20.6875 16.5625 21.2125C15.2951 21.7375 13.941 22 12.5 22Z" fill="currentColor" />
    </svg>

const LineChartIcon = () =>
    <svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.25 22.75V20.6917L4.875 19.0667V22.75H3.25ZM7.71875 22.75V16.3583L9.34375 14.7333V22.75H7.71875ZM12.1875 22.75V14.7333L13.8125 16.3854V22.75H12.1875ZM16.6563 22.75V16.3854L18.2813 14.7604V22.75H16.6563ZM21.125 22.75V12.025L22.75 10.4V22.75H21.125ZM3.25 16.3583V14.0563L10.8333 6.52708L15.1667 10.8604L22.75 3.25V5.55208L15.1667 13.1625L10.8333 8.82917L3.25 16.3583Z" fill="currentColor" />
    </svg>

const SortIcon = ({ isAscending }) => isAscending
    ? <svg width="22" height="22" viewBox="0 0 71 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="43.1974" y="9.97937" width="14" height="3" rx="1.5" transform="rotate(-180 43.1974 9.97937)" fill="#131C4E"/>
        <rect x="18.2601" y="12.0325" width="14" height="3" rx="1.5" transform="rotate(-134.914 18.2601 12.0325)" fill="#131C4E"/>
        <rect x="0.600494" y="9.89954" width="14" height="3" rx="1.5" transform="rotate(-45 0.600494 9.89954)" fill="#131C4E"/>
        <rect x="52.1974" y="23.9794" width="23" height="3" rx="1.5" transform="rotate(-180 52.1974 23.9794)" fill="#131C4E"/>
        <rect x="61.1974" y="37.9794" width="32" height="3" rx="1.5" transform="rotate(-180 61.1974 37.9794)" fill="#131C4E"/>
        <rect x="70.1974" y="50.9794" width="41" height="3" rx="1.5" transform="rotate(-180 70.1974 50.9794)" fill="#131C4E"/>
        <rect x="12" y="0.615234" width="54" height="3" rx="1.5" transform="rotate(90 12 0.615234)" fill="#131C4E"/>
    </svg>
    : <svg width="22" height="22" viewBox="0 0 71 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="29.1974" y="48.8019" width="14" height="3" rx="1.5" fill="#131C4E"/>
        <rect x="2.72499" y="43.4053" width="14" height="3" rx="1.5" transform="rotate(45.086 2.72499 43.4053)" fill="#131C4E"/>
        <rect x="20.3846" y="45.5382" width="14" height="3" rx="1.5" transform="rotate(135 20.3846 45.5382)" fill="#131C4E"/>
        <rect x="29.1974" y="34.8019" width="23" height="3" rx="1.5" fill="#131C4E"/>
        <rect x="29.1974" y="20.8019" width="32" height="3" rx="1.5" fill="#131C4E"/>
        <rect x="29.1974" y="7.80188" width="41" height="3" rx="1.5" fill="#131C4E"/>
        <rect x="8.98512" y="54.8225" width="54" height="3" rx="1.5" transform="rotate(-90 8.98512 54.8225)" fill="#131C4E"/>
    </svg>
    

const AnalyticsCard = ({ title, amount, hideToggle, hideAction, showSort, children, selectedDataType, setSelectedDataType, isAscending, setIsAscending, headerHeight, onDetailClick, padding, isLoading, isEmpty }) =>
    <div className="relative border border-neutral-60 rounded-md pl-6 h-fit">
        <div className={`
            flex justify-between items-center py-6 pr-4 pl-0 border-b border-neutral-60
            ${headerHeight || 'h-[80px]'}
        `}>
            <div className="flex gap-1 items-center text-[17px] w-full">
                {typeof title === 'string'
                    ? <h1>{ title }</h1>
                    : title
                }
                {!!amount && <span className="font-bold">{ `(${amount})` }</span>}
            </div>

            <div className="flex gap-3.5">
                {!hideToggle && 
                    <RadioSwitch
                        padding="p-0"
                        selected={selectedDataType}
                        setSelected={setSelectedDataType}
                        items={[
                            { icon: <PieChartIcon />, value: dataType.SNAPSHOT, tooltip: 'Huidige data' },
                            { icon: <LineChartIcon />, value: dataType.RANGE, tooltip: 'Data over tijd' },
                        ]}
                    />
                }
                {!hideAction && showSort && 
                    <IconButton 
                        onClick={setIsAscending} 
                        icon={<SortIcon isAscending={isAscending} />} 
                        label={isAscending ? 'Oplopend' : 'Aflopend' } 
                    /> 
                }
                {!hideAction && !showSort && <IconButton onClick={onDetailClick} /> }
            </div>
        </div>
        <div className={`relative flex flex-col justify-center ${selectedDataType === dataType.RANGE ? 'min-h-[333px]' : ''} ${selectedDataType === dataType.SNAPSHOT ? 'min-h-[214px]' : ''}`}>
            <LoadingWrapper containerClass={`${padding || 'py-6'} flex flex-col justify-center relative`} isLoading={isLoading} preRenderChildren>
                {isEmpty && !isLoading
                    ? <MessageDisplay title="Geen data gevonden" text="De data voor deze grafiek kon niet worden opgehaald" />
                    : children
                }
            </LoadingWrapper>
        </div>
    </div>

export default AnalyticsCard
