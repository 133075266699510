import { platformCollaborationLabels } from "../../config/constants"
import { LineChart } from "../charts"

const PlatformCollaborations = ({ data = [] }) => {
    let parsedData = []

    if (data.length) {
        const allowedTypes = [
            'coach_client_avg_count',
            'org_client_avg_count',
        ]
        const pairedData = {}

        data.forEach(item => {
            allowedTypes.forEach(type => {
                if (pairedData[type]) {
                    pairedData[type].push([item.timestamp, Math.ceil(item[type])])
                } else {
                    pairedData[type] = [[item.timestamp, Math.ceil(item[type])]]
                }
            })
        })

        Object.keys(pairedData).forEach(key => parsedData.push({ name: platformCollaborationLabels[key], data: pairedData[key] }))
    }

    return <LineChart data={parsedData} height={500} />
}

export default PlatformCollaborations
