import { caseLabels, dataType } from "../../config/constants";
import AnalyticsCard from "../AnalyticsCard";
import { LineChart, PieChart } from "../charts";
import DataSection from "./DataSection";

const Chart = ({...props}) => <PieChart {...props} data={props.data} labels={props.labels} chart={{ height: 250, offsetX: 0 }} legend={{ position: 'bottom', horizontalAlign: 'left' }}/>

const EndedCases = ({ setting, set, data = [], loading, onDetailClick, isDetailPage }) => {
    let firstChart = [], secondChart = [], secondLabels = []

    const endedCases = +data[0]?.project_closed_count
    const pendingCancelled = +data[0]?.project_pending_cancelled_count
    const midphaseCancelled = +data[0]?.project_midphase_cancelled_count

    if (data.length) {
        if (setting === dataType.SNAPSHOT) {
            // firstChart = [+data[0].project_completed_continue_count, +data[0].project_completed_stop_count]
            // firstLabels = [caseLabels.project_completed_continue_count, caseLabels.project_completed_stop_count]

            // add custom 'other cancelled' count' value (last item in secondChart array)
            secondChart = [pendingCancelled, midphaseCancelled, endedCases - pendingCancelled - midphaseCancelled]
            secondLabels = [caseLabels.project_pending_cancelled_count, caseLabels.project_midphase_cancelled_count, caseLabels.project_other_cancelled_count]
        } else {
            const allowedTypes = [
                // 'project_completed_continue_count',
                // 'project_completed_stop_count',
                'project_pending_cancelled_count',
                'project_midphase_cancelled_count',
                'project_other_cancelled_count'
            ]
            const pairedData = {}

            // add custom 'other cancelled' count' value
            const parsedData = data.map(item => ({
                ...item,
                project_other_cancelled_count: +item.project_closed_count - +item.project_pending_cancelled_count + +item.project_midphase_cancelled_count
            }))

            parsedData.forEach(item => {
                allowedTypes.forEach(type => {
                    if (pairedData[type]) {
                        pairedData[type].push([item.timestamp, +item[type]])
                    } else {
                        pairedData[type] = [[item.timestamp, +item[type]]]
                    }
                })
            })

            Object.keys(pairedData).forEach(key => firstChart.push({ name: caseLabels[key], data: pairedData[key] }))
        }
    }

    return isDetailPage
        ? <LineChart data={firstChart} height={500} />
        : <AnalyticsCard
            isEmpty={data.length === 0}
            isLoading={loading}
            padding={setting === dataType.SNAPSHOT ? 'p-0' : undefined}
            title="Beëindigde trajecten"
            selectedDataType={setting}
            setSelectedDataType={set}
            onDetailClick={onDetailClick}
        >
            {setting === dataType.SNAPSHOT && <>
                <div className="grid grid-cols-2 -ml-6">
                    <div className="border-r border-r-neutral-60 py-6">
                        <div className="flex flex-col ml-6 mb-6">
                            <DataSection data={data[0]?.project_completed_count} label={caseLabels.project_completed_count} roundNumber />
                        </div>
                        {/* <Chart data={firstChart} labels={firstLabels}/> */}
                    </div>
                    <div className="py-6">
                        <div className="flex flex-col ml-6 mb-6">
                            <DataSection data={endedCases} label={caseLabels.project_closed_count} roundNumber />
                        </div>
                        <Chart data={secondChart} labels={secondLabels}/>
                    </div>
                </div>
                {/* <div className="flex flex-col pr-6 py-6 border-t border-t-neutral-60">
                    <DataSection data={7} label="Voortgezette trajecten" />
                </div> */}
            </>}
            {setting === dataType.RANGE && <LineChart data={firstChart} />}
        </AnalyticsCard>
}

export default EndedCases
