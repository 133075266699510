import _ from 'lodash'
import { useState } from "react"
import { apiUrlCall } from '@plinkrlabs/utils'
import { credsValid, useConnectActions, useCredentials, waitOn } from '@plinkrlabs/connect-hooks'
import { getEnv, getFrequencyCount, toSnakeCase } from '../helper'

export const interfaces = {
    USER_STATUS: 'user_status_count',
    QUESTIONAIRE: 'questionaire_count',
    BUDGET_STATS: 'budget_stats',
    BUDGET_AVERAGES: 'budget_avg',
    ONBOARDING: 'onboarding_stats',
    PLATFORM_COLLABORATIONS: 'client_stats',
    CASES: 'hub_case_stats'
}

const getRangeBody = data => {
    const { startDate, endDate, filters } = data
    const start = startDate || new Date().toISOString(), end = endDate || new Date().toISOString()
    let frequency = data.frequency

    if (!frequency) {
        if (start && end) {
            const diff = Math.abs(Date.parse(start) - Date.parse(end))
            const diffWeeks = Math.ceil(diff / (1000 * 60 * 60 * 24 * 7))
            if (diffWeeks > 24) {
                frequency = 'month'
            } else if (diffWeeks > 6) {
                frequency = 'week'
            } else {
                frequency = 'day'
            }
        }
    }

    return {
        frequency_type: frequency,
        start_date: Math.floor(new Date(start).getTime() / 1000),
        frequency_count: getFrequencyCount(frequency, start, end),
        filters
    }
}

const useAnalytics = () => {
    const credentials = useCredentials()
    const { refreshCredentials } = useConnectActions()

    const [isLoading, setIsLoading] = useState({ range: {}, snapshot: {} })
    const [data, setResults] = useState({ range: {}, snapshot: {} })

    const apiCall = async (path, body = {}, method) => {
        if (!credsValid(credentials)) {
            refreshCredentials()
            await waitOn(() => credsValid(credentials))
        }

        try {
            const url = getEnv() === 'dev'
                ? 'https://api-root-dev.plinkr.nl/analytics'
                : 'https://api-root.plinkr.nl/analytics'

            const res = await apiUrlCall(
                [path, body, method],
                url,
                credentials
            )
            return res
        } catch (e) {
            let error
            switch (_.get(e, 'response.status')) {
                default:
                    error = _.get(e, 'response.data.message', e)
            }
            console.error(error)
            throw new Error('ContentProvider.apiCall error:', e)
        }
    }

    const getField = async (type, inf, preBody) => {
        setIsLoading(v => ({ ...v, [type]: { ...v[type], [id]: true } }))
        const isRange = type === 'range'
        const body = isRange ? getRangeBody(preBody) : toSnakeCase(preBody)
        const id = inf + (body.filters?.reduce((acc, { field, value }) => acc + `&${field}=${value}`, '') || '')

        try {
            const { data: res } = await apiCall(
                isRange
                    ? `metrics/query/${inf}`
                    : `metrics/query/${inf}/${type}`,
                {
                    body
                },
                'post'
            )
            setResults(v => ({ ...v, [type]: { ...v[type], [id]: res } }))
        } finally {
            setIsLoading(v => ({ ...v, [type]: { ...v[type], [id]: false } }))
        }
    }

    const getLoading = (type, inf, preBody = {}) => {
        const body = type === 'range' ? getRangeBody(preBody) : toSnakeCase(preBody)
        const id = inf + (body.filters?.reduce((acc, { field, value }) => acc + `&${field}=${value}`, '') || '')

        return isLoading[type][id]
    }

    const fetchData = async (...params) => getField(params[1]?.startDate || params[1]?.endDate ? 'range' : 'snapshot', ...params)

    const getData = (type, inf, preBody = {}) => {
        const body = type === 'range' ? getRangeBody(preBody) : toSnakeCase(preBody)
        const id = inf + (body.filters?.reduce((acc, { field, value }) => acc + `&${field}=${value}`, '') || '')

        return data[type][id] || []
    }

    return [data, { fetchData, getData, getLoading }, isLoading]
}

export default useAnalytics