import { useRef } from 'react'
import _ from 'lodash'
import { Button, Textfield, Table, Modal, Toggler, Paginator } from '@plinkrlabs/plinkr-ui'
import LoadingWrapper from '../components/generic/LoadingWrapper'
import { useNavigate } from 'react-router-dom'
import { useLocalized } from '../config/localized-context'
import useCompanies from '../utils/hooks/useCompanies'
import toast from "react-hot-toast"
import FormHandler from '../components/forms/FormHandler'

import { ReactComponent as Arrow } from '../assets/svg/arrow.svg'
import { ReactComponent as Search } from '../assets/svg/search.svg'

const CompanyPaginator = ({ pagination, setPagination }) => (
    <div className="my-5">
        <Paginator
            pages={Math.ceil(pagination.total / pagination.limit)}
            currentPage={Math.floor(pagination.offset / pagination.limit) + 1}
            displayAmount={2}
            onChange={page => setPagination({ offset: pagination.limit * (page - 1) })}
            hasInput
        />
    </div>
)

const Companies = () => {
    const { companies, filter, setFilter, pagination, setPagination, createCompany, isLoading } = useCompanies()
    const { localized } = useLocalized()
    const modalRef = useRef()
    const navigate = useNavigate()

    const parseCompanies = companies => _.map(companies, company => ({
        ...company,
        alternative_names: _.slice(_.get(company, 'alternative_names'), 0, 10)
    }))

    return (
        <div className="flex flex-col gap-2 p-9 overflow-x-hidden">
            {/* Heading */}
            <div className="flex gap-4">
                <h1 className="mr-auto text-primary text-[1.75rem] font-ALSSchlangeslabBold">{localized('companies')}</h1>
                <Textfield
                    size={Textfield.sizes.MEDIUM}
                    icon={<Search />}
                    value={filter.search}
                    onChange={e => setFilter({ search: e })}
                    maxWidth="120px"
                    focusWidth="240px"
                    placeholder="Zoeken"
                />
                <Toggler
                    toggler={open => <Button color={Button.colors.SECONDARY} padding={Button.paddings.MEDIUM} label={localized('add_company')} target={open} />}
                    content={close =>
                        <Modal
                            ref={modalRef}
                            title={localized('add_company')}
                            closeHandler={close}
                            showCloseButton
                        >
                            <FormHandler
                                onSubmit={values =>
                                    createCompany(values)
                                        .then(modalRef.current.close)
                                        .then(() => toast.success(localized('company_added')))
                                        .catch(() => toast.error(localized('toast_general_error')))}
                                type="add_company" />
                        </Modal>
                    }
                />
            </div>
            <span className="text-primary mb-6">Aantal: {_.get(companies, 'pagination.total')}</span>

            {/* Content */}
            <LoadingWrapper isLoading={isLoading}>
                <CompanyPaginator pagination={pagination} setPagination={setPagination} />
                <Table
                    keyExtractor={item => item.id}
                    columns={[
                        {
                            key: 'name', label: localized('company_name'), width: '40%', isSortable: false, format: (value, item) => <div className="flex flex-col gap-1 whitespace-pre-wrap">
                                {value}
                                {item.alternative_names.length > 0 &&
                                    <span className="text-xs text-primary-400">
                                        <span className="font-bold">{localized('extra_company_names')}: </span>
                                        <br />
                                        {item.alternative_names.map((name, index) => <span key={index}>{name}{item.alternative_names[index + 1] && ', '}</span>)}
                                        {item.alternative_names.length >= 10 && <span className="font-bold"> en meer</span>}
                                    </span>}
                            </div>
                        },
                        // { key: 'coc_number', label: localized('coc_number'), width: '16%', isSortable: true },
                        // { key: 'kind', label: localized('company_type'), width: '16%', isSortable: true },
                        {
                            key: 'address', path: 'address.postal', label: localized('address'), width: '21%', isSortable: false, format: (_value, item) => (item.address.street || item.address.mail_box)
                                ? <div className="flex flex-col gap-1 whitespace-pre-wrap break-all">
                                    {item.address.mail_box
                                        ? <p>Postbus {item.address.mail_box}</p>
                                        : <p>{item.address.street} {item.address.number} {item.address.addition}</p>
                                    }
                                    <p>{item.address.postal} {item.address.city}</p>
                                </div>
                                : '-'
                        },
                        {
                            key: 'email', label: localized('title_contact'), width: '30%', isSortable: false, format: (value, item) => <div className="flex flex-col gap-1 whitespace-pre-wrap break-all">
                                {value && <span className="font-bold">{value}</span>}
                                {item.phone && <span>{item.phone} </span>}
                                {item.website && <span>{item.website}</span>}
                            </div>
                        },
                        { key: 'arrow', format: () => <Arrow /> },
                    ]}
                    data={parseCompanies(_.get(companies, 'items'))}
                    // initialSelectedCol="name"
                    onSort={e => setFilter({ sort_by: e.col, sort_order: e.order })}
                    onRowClick={e => navigate(`/companies/${e.id}`)}
                />
                <CompanyPaginator pagination={pagination} setPagination={setPagination} />

            </LoadingWrapper>
        </div>
    )
}

export default Companies