
const DataSection = ({ label, data, isCurrency, roundNumber }) => {
    // devide by 100 if it's a currency, because data is in cents
    const number = parseFloat(isCurrency ? data / 100 : data).toFixed(2)

    return (
        <div className="flex flex-col gap-1">
            <div className="flex items-center text-[16px] text-primary font-bold">
                { isCurrency && <span className="mr-2">€</span> }
                {number && <span>
                    { isCurrency
                        ? number.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                        : roundNumber ? Math.round(number) : number
                    }
                </span>}
            </div>
            <span className="text-[13px] text-neutral-80 -mt-1 first-letter:capitalize">{ label }</span>
        </div>
    )
}

export default DataSection
