import * as Yup from 'yup'
import { useLocalized } from './localized-context'
import { isValidPhoneNumber } from 'libphonenumber-js'
import _ from 'lodash'

const useForms = (type, selectOptions) => {
    const { localized } = useLocalized()

    const companyGeneralValidators = {
        name: Yup.string().max(255).required(localized('error_company_name_required')),
        company_type: Yup.string().max(255).required(localized('error_company_type_required')),
        coc_number: Yup.string().max(255).required(localized('error_coc_number_required')),
    }
    const companyAddressValidators = {
        street: Yup.string().max(255).required(localized('error_street_name_required')),
        number: Yup.number().min(1).required(localized('error_street_number_required')),
        postal: Yup.string().max(255).required(localized('error_postal_required')),
        city: Yup.string().max(255).required(localized('error_city_name_required')),
    }
    const companyContactValidators = {
        website: Yup.string().max(255).required(localized('error_website_required')),
        email: Yup.string().email(localized('error_valid_email')).max(255).required(localized('error_email_required')),
        phone: Yup.string().test('valid-phone', (localized('error_valid_phone_number')), e => !_.isEmpty(e) ? isValidPhoneNumber(e, 'NL') : true),
    }
    const articleValidators = {
        image_url: Yup.string().max(255).required(localized('error_article_image_required')),
        image_color: Yup.string().max(255).required(localized('error_article_image_color_required')),
        title: Yup.string().max(255).required(localized('error_title_required')),
    }

    const validators = {
        add_company: {
            ...companyGeneralValidators,
            ...companyAddressValidators,
            ...companyContactValidators
        },
        edit_company_general: companyGeneralValidators,
        edit_company_address: companyAddressValidators,
        edit_company_contact: companyContactValidators,
        add_article: articleValidators,
        edit_article: articleValidators
    }

    const companyGeneralInputs = [
        { name: 'name', label: 'company_name', type: 'text' },
        { name: 'alternative_names', label: 'alternative_names', type: 'multiInput' },
        {
            name: 'company_type', label: 'company_type', type: 'select', options: [
                { label: 'Test type', value: 'TestValue' },
                { label: 'Test type 2', value: 'TestValue2' }
            ]
        },
        { name: 'coc_number', label: 'coc_number', type: 'text' },
    ]
    const companyAddressInputs = [
        { name: 'street', label: 'street_name', type: 'text' },
        { name: 'number', label: 'street_number', type: 'number' },
        { name: 'addition', label: 'house_number_addition', type: 'string', optional: true },
        { name: 'mail_box', label: 'mail_box', type: 'text', optional: true },
        { name: 'postal', label: 'postal', type: 'text' },
        { name: 'city', label: 'city_name', type: 'text' },
    ]
    const companyContactInputs = [
        { name: 'website', label: 'website', type: 'text' },
        { name: 'email', label: 'email', type: 'email' },
        { name: 'phone', label: 'phone_number', type: 'phone', optional: true },
    ]
    const articleInputs = [
        { name: 'title', label: 'Titel', type: 'text' },
        {
            name: 'image_url', label: 'Afbeelding', type: 'select', options: [
                { label: 'actie_article', value: 'actie_article.svg' },
                { label: 'calculate_article', value: 'calculate_article.svg' },
                { label: 'check_article', value: 'check_article.svg' },
                { label: 'help_article', value: 'help_article.svg' },
                { label: 'house_article', value: 'house_article.svg' },
                { label: 'money_article', value: 'money_article.svg' },
                { label: 'owl_article', value: 'owl_article.svg' },
                { label: 'podcast_article', value: 'podcast_article.svg' },
                { label: 'power_article', value: 'power_article.svg' },
                { label: 'research_article', value: 'research_article.svg' },
                { label: 'search_article', value: 'search_article.svg' },
                { label: 'taxes_article', value: 'taxes_article.svg' },
            ]
        },
        { name: 'image_color', label: 'Achtergrondkleur', type: 'color' },
        { name: 'sub_title', label: 'Subtitel', type: 'text', optional: true },
        { name: 'label', label: 'Label', type: 'text', optional: true },
        { name: 'url', label: 'URL', type: 'text', optional: true },
    ]

    const fields = {
        add_company: [
            ...companyGeneralInputs,
            ...companyAddressInputs,
            ...companyContactInputs
        ],
        edit_company_general: companyGeneralInputs,
        edit_company_address: companyAddressInputs,
        edit_company_contact: companyContactInputs,
        add_article: articleInputs,
        edit_article: articleInputs
    }

    return [Yup.object().shape(validators[type]), fields[type]]
}

export { useForms }
