import React, { useReducer, createContext, useContext } from 'react'
import NL from '../locale/nl.json'
import _ from 'lodash'
import PropTypes from 'prop-types'

const translations = {
    nl: NL,
}

const getCopy = langCode => key => translations[langCode][key] || key

const copyReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            return {
                langCode: action.payload,
                localized: getCopy(action.payload)
            }
        default:
            return { ...state }
    }
}

const initialState = {
    langCode: 'nl',
    localized: getCopy('nl')
}

const CopyContext = createContext(initialState)

const CopyProvider = ({children}) => {
    const [state, dispatch] = useReducer(copyReducer, initialState)

    return (
        <CopyContext.Provider value={{ ...state, dispatch }}>
            {children}
        </CopyContext.Provider>
    )
}

CopyProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

const useLocalized = () => {
    const context = useContext(CopyContext)

    if (context === undefined) {
        throw new Error('localizedContext must be used within a CopyProvider')
    }

    const { langCode, localized, dispatch } = context

    const setLanguage = (locale) => dispatch({type: 'SET_LANGUAGE', payload: locale})

    const lookupTranslationKey = (value) => {
        const labels = _.invert(_.get(translations, langCode, {}))
        return _.get(labels, value, value)
    }

    return { localized, setLanguage, lookupTranslationKey }
}

export { CopyProvider, useLocalized }
