import { Navigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { SideMenu, Layout, ProfileMenuHeader, ProfileMenu, Toast } from '@plinkrlabs/plinkr-ui'
import { ReactComponent as Logo } from './assets/svg/logo.svg'
import Menu from './components/menu/Menu'
import useRoutes, { routeTypes } from './config/useRoutes'
import { Route, Routes } from 'react-router-dom'
import { MessageProvider } from '@plinkrlabs/notifications-mfe'
import _ from 'lodash'
import { getFullName } from './utils/helper'
import { useConnectActions, useConnectState } from '@plinkrlabs/connect-hooks'
import { ContentProvider } from './utils/ContentContext'

const AuthenticatedApp = () => {
    const routes = useRoutes()
    const { account } = useConnectState()
    const { logout } = useConnectActions()

    return (
        <ContentProvider>
            <MessageProvider>
                <Toaster position="top-right">
                    {t => <Toast {...t} appearance={t.type} onDismiss={() => toast.dismiss(t.id)}>{t.message}</Toast>}
                </Toaster>
                <Layout isVertical={false} header={
                    <SideMenu
                        header={<Logo />}
                        footer={
                            <ProfileMenu
                                name={getFullName(account)}
                                header={<ProfileMenuHeader name={getFullName(account)} subheader={_.get(account, 'email')} />}
                                modalPosition={ProfileMenu.modalPositions.TOPLEFT}
                                actions={[
                                    {
                                        label: 'Uitloggen',
                                        onClick: () => logout()
                                    }
                                ]} />
                        } >
                        <Menu items={_.filter(routes, route => route.menuItem)} />
                    </SideMenu>
                } scrollInContent>
                    <Routes>
                        {routes.map(route =>
                            <Route key={route.path} path={route.path} exact={route.exact} element={route.element} />
                        )}
                        <Route path="*" element={<Navigate to={routeTypes.ANALYTICS_PATH} replace />} />
                    </Routes>
                </Layout>
            </MessageProvider>
        </ContentProvider>
    )
}

export default AuthenticatedApp
