import { useEffect, useRef } from "react"
import ReactApexChart from "react-apexcharts"

const PieChart = ({ data, labels, legendTitle, legend = {}, chart = {} }) => {
    const chartRef = useRef()

    const colors = ['#6CD9e2', '#43A9CC', '#2C7BAF', '#26518A', '#262C5F']

    const combinedArray = data.map((num, index) => ({ num, label: labels[index] }))
    combinedArray.sort((a, b) => b.num - a.num)

    const sortedData = combinedArray.map(obj => obj.num)
    const sortedLabels = combinedArray.map(obj => obj.label)

    const customSeries = legendTitle ? [0, ...sortedData] : sortedData
    const customLabels = legendTitle ? [legendTitle, ...sortedLabels] : sortedLabels
    const customColors = legendTitle ? ['', ...colors] : colors
    const options = {
        series: customSeries,
        chart: {
            height: 160,
            offsetX: -20,
            type: 'donut',
            fontFamily: 'karla',
            animations: {
                enabled: false,
            },
            ...chart
        },
        labels: customLabels,
        colors: customColors,
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            pie: {
                donut: {
                  size: '50%',
                },
                expandOnClick: false
            }
        },
        stroke: {
            width: 0,
        },
        legend: {
            width: 310,
            position: 'right',
            offsetX: -10,
            horizontalAlign: 'center',
            markers: {
                radius: 4,
                width: 14,
                height: 14,
                offsetX: -4,
                offsetY: 3,
            },
            onItemHover: {
                highlightDataSeries: !legendTitle
            },
            onItemClick: {
                toggleDataSeries: false
            },
            formatter: (label, opts) => legendTitle && opts.seriesIndex === 0 
                ? `<div class="text-neutral-80 text-[14px] -ml-[6px] mb-[4px]">${label}</div>` 
                : [opts.w.globals.labels[opts.seriesIndex], ' (', opts.w.globals.series[opts.seriesIndex], ')'].join(''),
            ...legend
        },
        // Styled like plinkr-ui's tooltip
        tooltip: {
            custom: ({ series, seriesIndex, w }) => 
                `<div class="text-neutral-20 duration-500 w-max z-50 text-[0.8em] font-karla px-2 py-1 shadow-md rounded pointer-events-none select-none" style="background-color: ${w.config.colors[seriesIndex]};">
                    ${customLabels[seriesIndex]}: ${series[seriesIndex]}
                </div>`
        },
        states: {
            active: {
                filter: {
                    type: 'none'
                }
            }
        }
    }

    const handleElementMouseEnter = rel => {
        const segments = document.querySelectorAll('.apexcharts-series')

        Array.from(segments).forEach(segment => {
            if (chartRef.current.contains(segment)) {
                if (segment.getAttribute('rel') !== rel) {
                    segment.classList.add('legend-mouseover-inactive')
                } else {
                    segment.classList.remove('legend-mouseover-inactive')
                }
            }
        })
    }

    const handleElementMouseLeave = () => {
        const segments = document.querySelectorAll('.apexcharts-series')

        Array.from(segments).forEach(segment => {
            segment.classList.remove('legend-mouseover-inactive')
        })
    }

    const handleCallbacks = (elms, remove) => {
        if (!legendTitle) return
        Array.from(elms).forEach(elm => {
            const rel = elm.getAttribute('rel')

            if (rel !== '1' && chartRef.current?.contains(elm)) {
                elm[remove ? 'removeEventListener' : 'addEventListener']('mouseenter', () => handleElementMouseEnter(rel))
                elm[remove ? 'removeEventListener' : 'addEventListener']('mouseleave', handleElementMouseLeave)
            }
        })
    }

    useEffect(() => {
        const elms = document.querySelectorAll('.apexcharts-legend-series')
        
        handleCallbacks(elms)

        return () => handleCallbacks(elms, true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
          
    return <div ref={chartRef}>
        <ReactApexChart options={options} series={options.series} type={options.chart.type} height={options.chart.height} />
    </div>
}

export default PieChart
