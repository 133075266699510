const getIcon = type => {
    switch(type) {
        case 'upload':
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox="4.5 1.5 15 21"><path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"></path></svg>
        case 'note':
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox="2.25 1.5 20.25 20.25"><path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"></path></svg>         
        case 'client':
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox="1.5 2.43 21 19.32"><path strokeLinecap="round" strokeLinejoin="round" d="M15 19.13a9.38 9.38 0 0 0 2.63.37 9.34 9.34 0 0 0 4.12-.95 4.13 4.13 0 0 0-7.54-2.5m.79 3.08v0c0-1.12-.28-2.16-.79-3.07m.79 3.07v.1A12.32 12.32 0 0 1 8.62 21c-2.33 0-4.5-.65-6.37-1.77v-.1a6.38 6.38 0 0 1 11.96-3.07M12 6.38a3.38 3.38 0 1 1-6.75 0 3.38 3.38 0 0 1 6.75 0zm8.25 2.25a2.63 2.63 0 1 1-5.25 0 2.63 2.63 0 0 1 5.25 0z"></path></svg>
        case 'coach':
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox="1.5 3 21 18.75"><path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.1 9.1 0 0 0 3.74-.48 3 3 0 0 0-4.68-2.72m.94 3.2v.03c0 .22-.01.45-.04.67A11.94 11.94 0 0 1 12 21c-2.17 0-4.2-.58-5.96-1.58a6.06 6.06 0 0 1-.04-.7m12 0a5.97 5.97 0 0 0-.94-3.2m0 0A6 6 0 0 0 12 12.75a6 6 0 0 0-5.06 2.77m0 0a3 3 0 0 0-4.68 2.72 8.99 8.99 0 0 0 3.74.48m.94-3.2a5.97 5.97 0 0 0-.94 3.2m9-11.97a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0z"></path></svg>    
        case 'cases':
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox="3 1.5 18 20.99"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.1c0-1.13-.84-2.09-1.98-2.18a48.42 48.42 0 0 0-1.12-.08m-5.8 0c-.07.2-.1.43-.1.66 0 .41.34.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.66m-5.8 0a2.25 2.25 0 0 1 2.15-1.59H15c1.01 0 1.87.67 2.15 1.59m-5.8 0-1.12.08A2.18 2.18 0 0 0 8.25 6.1v2.14m0 0H4.87c-.62 0-1.12.5-1.12 1.13v11.24c0 .63.5 1.13 1.13 1.13h9.75c.62 0 1.12-.5 1.12-1.13V9.38c0-.62-.5-1.12-1.13-1.12H8.25zM6.75 12h0v0h0v0zm0 3h0v0h0v0zm0 3h0v0h0v0z"></path></svg>
        case 'closed-cases':
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox="3 1.5 18 21"><path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.84c-.07.2-.1.43-.1.66 0 .41.34.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.66m-5.8 0a2.25 2.25 0 0 1 2.15-1.59H15c1.01 0 1.87.67 2.15 1.59m-5.8 0-1.12.08A2.18 2.18 0 0 0 8.25 6.1v2.14m8.9-4.41 1.12.08a2.18 2.18 0 0 1 1.98 2.19v10.4A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.87c-.62 0-1.12.5-1.12 1.13v11.24c0 .63.5 1.13 1.13 1.13h9.75c.62 0 1.12-.5 1.12-1.13v-1.87m-7.5-10.5h6.38c.62 0 1.12.5 1.12 1.13v9.37m-8.25-3 1.5 1.5 3-3.75"></path></svg>
        case 'upcoming-cases':
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox="0 -.07 18 21"><path strokeLinecap="round" strokeLinejoin="round" d="M8.35 2.27c-.07.2-.1.43-.1.66 0 .41.34.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.66m-5.8 0A2.25 2.25 0 0 1 10.5.68H12c1.01 0 1.87.67 2.15 1.59m-5.8 0-1.12.08a2.18 2.18 0 0 0-1.98 2.18v2.14m8.9-4.41 1.12.08a2.18 2.18 0 0 1 1.98 2.19v10.4A2.25 2.25 0 0 1 15 17.18h-2.25m-7.5-10.5H1.87c-.62 0-1.12.5-1.12 1.13v11.24c0 .63.5 1.13 1.13 1.13h9.75c.62 0 1.12-.5 1.12-1.13v-1.87m-7.5-10.5h6.38c.62 0 1.12.5 1.12 1.13v9.37m-8.39-5.35"/><path stroke="null" strokeLinecap="round" strokeLinejoin="round" d="M6.72 10.98v2.44h1.84m1.83 0a3.67 3.67 0 1 1-7.34 0 3.67 3.67 0 0 1 7.34 0z"/></svg>
        case 'tasks-completed':
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox="3.75 1.5 16.5 21"><path strokeLinecap="round" strokeLinejoin="round" d="M10.13 2.25h-4.5c-.63 0-1.13.5-1.13 1.13v17.25c0 .62.5 1.12 1.13 1.12h12.75c.62 0 1.12-.5 1.12-1.13v-9m-9.38-9.37h.38a9 9 0 0 1 9 9v.38m-9.38-9.38a3.38 3.38 0 0 1 3.38 3.38v1.5c0 .62.5 1.12 1.13 1.12h1.5a3.38 3.38 0 0 1 3.37 3.38M9 15l2.25 2.25L15 12"/></svg>
        case 'tasks-cancelled':  
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox=".75 0 16.5 21"><path strokeLinecap="round" strokeLinejoin="round" d="m6.75 11.41 4.5 4.5m0-4.5-4.5 4.5m9.75-3.16v-2.63a3.38 3.38 0 0 0-3.38-3.37h-1.5a1.13 1.13 0 0 1-1.12-1.13v-1.5A3.38 3.38 0 0 0 7.12.75H5.25m2.25 0H2.62c-.62 0-1.12.5-1.12 1.13v17.25c0 .62.5 1.12 1.13 1.12h12.75c.62 0 1.12-.5 1.12-1.13V9.76a9 9 0 0 0-9-9z"/></svg>
        case 'action-completed':  
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox="1.5 2.25 21 19.5"><path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.51a2.2 2.2 0 0 1 1.5 2.1v4.28c0 1.14-.85 2.1-1.98 2.2l-1.02.07v3.09l-3-3c-1.35 0-2.7-.06-4.02-.16a2.12 2.12 0 0 1-.82-.25m9.34-8.33a2.13 2.13 0 0 0-.48-.1 48.64 48.64 0 0 0-8.04 0 2.18 2.18 0 0 0-1.98 2.2v4.28c0 .84.46 1.58 1.16 1.95m9.34-8.33V6.64a3.22 3.22 0 0 0-2.76-3.24 48.45 48.45 0 0 0-6.24-.4c-2.11 0-4.2.14-6.24.4a3.22 3.22 0 0 0-2.76 3.24v6.22a3.22 3.22 0 0 0 2.76 3.24l1.74.2V21l4.16-4.16"/><path strokeLinecap="round" strokeLinejoin="round" d="m13.52 13.26 1.5 1.5 3-3.75"/></svg>  
        case 'action':
        default:
            return <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1" stroke="currentColor" className="h-full" viewBox="1.5 2.25 21 19.5"><path strokeLinecap="round" strokeLinejoin="round" d="M8.63 9.75a.38.38 0 1 1-.76 0 .38.38 0 0 1 .75 0zm0 0h-.38m4.13 0a.38.38 0 1 1-.76 0 .38.38 0 0 1 .76 0zm0 0H12m4.13 0a.38.38 0 1 1-.75 0 .38.38 0 0 1 .74 0zm0 0h-.38m-13.5 3.01c0 1.6 1.12 3 2.7 3.23 1.1.16 2.2.28 3.3.37V21l4.18-4.18a1.14 1.14 0 0 1 .78-.34 48.3 48.3 0 0 0 5.83-.5 3.22 3.22 0 0 0 2.71-3.22V6.74c0-1.6-1.12-3-2.7-3.23A48.4 48.4 0 0 0 12 3c-2.4 0-4.74.17-7.04.51a3.22 3.22 0 0 0-2.71 3.23v6.02z"/></svg>
    }
}

const StatisticCard = ({ title, number, icon, onClick }) =>
    <div onClick={onClick} className="
        flex flex-col gap-5 justify-between 
        text-primary border border-neutral-60 rounded-md p-6 cursor-pointer
        transition-colors
        hover:bg-primary-100 hover:border-primary
    ">
        <div>
            <div className="w-[50px] h-[50px]">{getIcon(icon)}</div>
            <span className="text-md mt-4 block text-neutral-80">{title}</span>
        </div>
        <span className="text-2xl font-bold justify-self-end">{parseFloat(Number(number)?.toFixed(2))}</span>
    </div>

export default StatisticCard
