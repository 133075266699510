import _ from "lodash"
import { dataType, graphTypes, hubUserTypeLabels, products, userStatusLabels, userTypes } from "../../config/constants"
import { rangeToApex, snapshotToApex } from "../../utils/helper"
import AnalyticsCard from "../AnalyticsCard"
import { LineChart, PieChart } from "../charts"

const Users = ({ title, data = [], loading, setting, settings, set, onDetailClick, userType, labels, isDetailPage }) => {
    const isHub = settings.filterProduct === products.HUB
    const isTotal = settings.filterGraph === graphTypes.TOTAL
    const defaultLabels = labels ? labels : settings.filterProduct === products.HUB ? hubUserTypeLabels : userStatusLabels
    const itemKey = settings.filterProduct === products.HUB ? 'account_type' : 'status'

    const getPlatformData = () => {
        // Filter data by user type
        const platformData = data.filter(item => item.account_type === userType)

        if (userType === userTypes.CLIENT) {
            // Only filter out 'registered' and 'activated' status for platform clients
            return platformData.filter(item => item.status !== 'registered' && item.status !== 'activated')
        }
        
        // For coaches:
        // Merge registered count into invited count
        const parsedPlatformData = platformData.map(item => {
            if (item.status === 'invited') {
                // find corresponding 'registered' item and add its count to the 'invited' item
                const correspondingTimestampItem = platformData.find(obj => obj.status === 'registered' && obj.timestamp === item.timestamp)
                return { ...item, count: +item.count + +correspondingTimestampItem.count } 
            }

            return item
        })

        // Remove entries with status 'registered' and 'activated'
        const finalData = parsedPlatformData.filter(item => item.status !== 'registered' && item.status !== 'activated')

        return finalData
    }

    const finalPlatformData = getPlatformData()

    const finalHubData = settings['user_status_count'] === dataType.SNAPSHOT
        ? [
            _.find(data, item => item?.account_type === userTypes.CLIENT && item?.status === 'activated'),
            _.find(data, item => item?.account_type === userTypes.COACH && item?.status === 'activated'),
            _.find(data, item => item?.account_type === userTypes.TRUSTEE && item?.status === 'activated'),
        ]
        : data.filter(item => item.status === 'activated')

    const rangeData = rangeToApex(
        isHub ? finalHubData : getPlatformData(), 
        defaultLabels,
        'account_type', 
        isHub ? undefined : userType, 
        itemKey
    )

    const totalRangeData = () => {
        let finalData = []
        const dataArray = []

        rangeData.data.forEach(item => {
            item.data.forEach(item => {
                const duplicate = dataArray.find(i => i[0] === item[0])
                if (duplicate) {
                    dataArray[duplicate] = [item[0], duplicate[1] += item[1]]
                } else {
                    dataArray.push(item)
                }
            })
        })
        
        finalData.push({ name: 'Totaal', data: dataArray })

        return finalData
    }

    const snapshotData = snapshotToApex(
        isHub ? finalHubData : finalPlatformData, 
        defaultLabels,
        'account_type', 
        isHub ? undefined : userType, 
        itemKey
    )

    return isDetailPage
        ? <LineChart height={500} data={isTotal ? totalRangeData() : rangeData.data}  />
        : <AnalyticsCard
            isEmpty={data.length === 0}
            isLoading={loading}
            title={title}
            amount={setting === dataType.SNAPSHOT ? (isHub ? finalHubData : finalPlatformData)?.reduce((acc, item) => acc + +item?.count, 0) : 0}
            selectedDataType={setting}
            setSelectedDataType={set}
            onDetailClick={onDetailClick}
        >
            {setting === dataType.SNAPSHOT
                ? <PieChart data={snapshotData.data} labels={snapshotData.labels} />
                : <LineChart data={rangeData.data} />
            }
        </AnalyticsCard>
}

export default Users
