import { useField } from 'formik'
import { useState, useEffect } from 'react'
import _ from 'lodash'

const useMultiInput = name => {
    const [inputValue, setInputValue] = useState('')

    const [, meta, helpers] = useField(name)
    const { value } = meta
    const { setValue } = helpers

    const deleteValue = item => setValue(value.filter(value => value !== item))

    const addValue = () => {
        if (inputValue && !_.find(value, item => item === inputValue)) {
            setValue([
                ...value,
                inputValue,
            ])
            setInputValue('')
        }
    }

    const keyDownHandler = event => {
        // if user pressed enter AND the correct input field is in focus
        if (event.key === 'Enter' && document.activeElement.id === name) {
            addValue()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler)

        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        }

    // eslint-disable-next-line
    }, [inputValue])

    return [{ setInputValue, deleteValue, addValue }, inputValue]
}

export default useMultiInput
