import { hubCaseTasksLabels } from "../../config/constants"
import { LineChart } from "../charts"

const HubCaseTasks = ({ data = [] }) => {
    let parsedData = []

    if (data.length) {
        const allowedTypes = [
            'project_completed_task_completed_avg_count',
            'project_midphase_cancelled_task_completed_avg_count',
        ]
        const pairedData = {}

        data.forEach(item => {
            allowedTypes.forEach(type => {
                if (pairedData[type]) {
                    pairedData[type].push([item.timestamp, Math.ceil(item[type])])
                } else {
                    pairedData[type] = [[item.timestamp, Math.ceil(item[type])]]
                }
            })
        })

        Object.keys(pairedData).forEach(key => parsedData.push({ name: hubCaseTasksLabels[key], data: pairedData[key] }))
    }

    return <LineChart data={parsedData} height={500} />
}

export default HubCaseTasks
