import { createContext, useReducer } from 'react'
import { apiUrlCall } from '@plinkrlabs/utils'
import _ from 'lodash'
import { useConnectActions, useCredentials, credsValid, waitOn } from '@plinkrlabs/connect-hooks'
import { getEnv } from './helper'

const initial = {
    companies: {
        pagination: { limit: 10, offset: 0, total: 0 },
        filter: { search: '',  sort_by: 'name', sort_order: 'asc' },
        items: [],
    },
    company: {},
    articles: null,
    errors: [],
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'REQUEST_FAILED':
            return {
                ...state,
                errors: _.concat(
                    _.get(state, 'errors', []),
                    { error: _.get(action, 'error', ''), timestamp: Date.now() }),
            }
        case 'COMPANY_LIST_FETCH_SUCCESS':
            return {
                ...state,
                companies: {
                    ...state.companies,
                    items: _.get(action, 'companies.data'),
                    pagination: {
                        ..._.get(state, 'companies.pagination'),
                        total: _.get(action, 'companies.total.value')
                    }
                }
            }
        case 'COMPANY_FETCH_SUCCESS':
            return {
                ...state,
                company: _.get(action, 'company')
            }
        case 'COMPANY_CREATE_SUCCESS':
            return {
                ...state,
                companies: {
                    ...state.companies,
                    items: [
                        ..._.get(state, 'companies.items', []),
                        _.get(action, 'company'),
                    ],
                    pagination: {
                        ..._.get(state, 'companies.pagination'),
                        total: _.get(state, 'companies.pagination.total') + 1,
                    }
                },
            }
        case 'COMPANY_UPDATE_SUCCESS':
            const updatedCompaniesState = [...state.companies.items]
            const indexToUpdate = _.findIndex(updatedCompaniesState, {id: _.get(action, 'company.id', '')})

            indexToUpdate > 0 && updatedCompaniesState.splice(indexToUpdate, 1, _.get(action, 'company', {}))

            return {
                ...state,
                company: {
                    ..._.get(state , 'company', {}),
                    ..._.get(action, 'company'),
                },
                companies: {
                    ...state.companies,
                    items: updatedCompaniesState
                }
            }
        case 'COMPANY_DELETE_SUCCESS':
            const filteredCompaniesState = [...state.companies.items]
            const indexToDelete = _.findIndex(filteredCompaniesState, {id: _.get(action, 'companyId', '')})

            indexToDelete > 0 && filteredCompaniesState.splice(indexToDelete, 1)

            return {
                ...state,
                companies: {
                    ...state.companies,
                    items: filteredCompaniesState
                }
            }
        case 'SET_COMPANY_FILTER':
            return {
                ...state,
                companies: {
                    ...state.companies,
                    filter: {
                        ..._.get(state, 'companies.filter', {}),
                    ..._.get(action, 'filter'),
                    }
                }
            }
        case 'SET_PAGINATION':
            return {
                ...state,
                [_.get(action, 'key')]: {
                    ..._.get(state, _.get(action, 'key'), {}),
                    pagination: {
                        ..._.get(state, [_.get(action, 'key'), 'pagination'], {}),
                        offset: _.get(action, 'offset', 0),
                    },
                }
            }
        case 'ARTICLE_LIST_FETCH_SUCCESS':
            return {
                ...state,
                articles: _.get(action, 'articles', [])
            }
        case 'ARTICLE_CREATE_SUCCESS':
            return {
                ...state,
                articles: [
                    ..._.get(state, 'articles', []),
                    _.get(action, 'article', {}),
                ],
            }
        case 'ARTICLE_UPDATE_SUCCESS':
            return {
                ...state,
                articles: _.map(state.articles, article => _.get(article, 'id') === _.get(action, 'id')
                     ? {...article, ..._.get(action, 'article')}
                     : article
                )
            }
        case 'ARTICLE_DELETE_SUCCESS':
            return {
                ...state,
                articles: _.filter(state.articles, article => _.get(article, 'id') !== _.get(action, 'id'))
            }
        default:
            return state
    }
}

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const credentials = useCredentials()
    const { refreshCredentials } = useConnectActions()
    const [{ companies, company, articles, errors }, dispatch] = useReducer(reducer, initial)

    const apiCall = async (path, data, method) => {
        if (!credsValid(credentials)) {
            refreshCredentials()
            await waitOn(() => credsValid(credentials))
        }

        try {
            const url = getEnv() === 'dev'
                ? 'https://api-root-dev.plinkr.nl/content'
                : 'https://api-root.plinkr.nl/content'

            const res = await apiUrlCall(
                [path, data, method],
                url,
                credentials
            )
            return res
        } catch (e) {
            let error
            switch (_.get(e, 'response.status')) {
                default:
                    error = _.get(e, 'response.data.message', e)
            }
            // dispatch({ type: 'REQUEST_FAILED', error })
            console.error(error)
            throw new Error('ContentProvider.apiCall error:', e)
        }
    }

    return (
        <ContentContext.Provider value={{ companies, company, articles, errors, dispatch, apiCall, credentials }}>
            {children}
        </ContentContext.Provider>
    )
}