
const products = {
    PLATFORM: 'platform',
    HUB: 'hub',
}

const userTypes = {
    CLIENT: 'client',
    COACH: 'coach',
    TRUSTEE: 'trustee',
}

const dataType = {
    SNAPSHOT: 'snapshot',
    RANGE: 'range',
}

const frequencyTypes = {
    DAYS: 'day',
    WEEKS: 'week',
    MONTHS: 'month',
    // YEARS: 'year',
}

const sort = {
    ASC: 'asc',
    DESC: 'desc',
}

const userStatusLabels = {
    active: 'Actief',
    inactive: 'Inactief',
    registered: 'Account niet geactiveerd',
    activated: 'Geactiveerd, geen wachtwoord ingesteld',
    not_logged_in: 'Geactiveerd, niet ingelogd',
    invited: 'Uitgenodigd',
}

const coachUserStatusLabels = {
    active: 'Actief',
    inactive: 'Inactief',
    invited: 'Uitgenodigd / Geregistreerd',
}

const hubUserTypeLabels = {
    client: 'Cliënt',
    trustee: 'Bewindvoerder',
    coach: 'Coach',
}

const caseLabels = {
    project_active_count: 'Lopende trajecten',
    project_pending_count: 'Aankomende trajecten',
    project_closed_count: 'Beëindigde trajecten',

    project_completed_count: 'Succesvol voltooid',
    project_completed_continue_count: 'Succesvol voltooid, eindbesluit is X',
    project_completed_stop_count: 'Succesvol voltooid, eindbesluit is Y',

    project_cancelled_count: 'Tussentijds beëindigd',
    project_pending_cancelled_count: 'Voor aanvraag beëindigd',
    project_midphase_cancelled_count: 'Traject tussentijds beëindigd',
    project_other_cancelled_count: 'Overig beëindigd',

    project_completed_task_completed_avg_count: 'Gemiddeld aantal voltooide taken per succesvol voltooid traject',
    project_midphase_cancelled_task_completed_avg_count: 'Gemiddeld aantal voltooide taken per tussentijds beëindigd traject'
}

const stressFormLabels = {
    completed: 'Voltooid',
    stopped: 'Tussentijds gestopt',
    pending: 'Niet ingevuld'
}

const platformClientOnboardingLabels = {
    task_zero_completed_count: '0 afgeronde stappen',
    task_gt0_lte5_completed_count: '1-5 afgeronde stappen',
    task_gt5_completed_acount: '5+ afgeronde stappen'
}

const userAverageLabels = {
    arrears_avg_count: 'Aantal achterstanden',
    debt_avg_count: 'Aantal schulden',
    debt_avg_sum: 'Som aan schulden',
    debt_has_collector_avg_count: 'Aantal overgedragen schulden',
    expense_avg_count: 'Aantal uitgaven',
    expense_avg_sum: 'Som aan uitgaven',
    earning_avg_count: 'Aantal inkomsten',
    earning_avg_sum: 'Som aan inkomsten'
}

const platformActionLabels = [
    'Aangemaakte acties',
    'Afgeronde acties',
]

const platformNotesAndUploadsLabels = [
    'Notities',
    'Uploads',
]

const platformCollaborationLabels = {
    coach_client_avg_count: 'Aantal cliënten per coach',
    org_client_avg_count: 'Aantal coaches per organisatie',
}

const hubCasesLabels = {
    project_active_count: 'Lopende trajecten',
    project_pending_count: 'Aankomende trajecten',
    project_completed_count: 'Beëindigde trajecten'
}

const hubCaseTasksLabels = {
    project_completed_task_completed_avg_count: 'Gemiddeld aantal voltooide taken per succesvol voltooid traject',
    project_midphase_cancelled_task_completed_avg_count: 'Gemiddeld aantal voltooide taken per tussentijds beëindigd traject'
}

const userDetailTitles = {
    [products.PLATFORM]: {
        [userTypes.CLIENT]: 'Gebruikers - Platform cliënten',
        [userTypes.COACH]: 'Gebruikers - Platform coaches'
    },
    [products.HUB]: 'Geactiveerde gebruikers - Hub'
}

const graphTypes = {
    DETAIL: 'detail',
    TOTAL: 'total',
}

export {
    products,
    userTypes,
    dataType,
    sort,
    userStatusLabels,
    hubUserTypeLabels,
    caseLabels,
    stressFormLabels,
    platformClientOnboardingLabels,
    platformCollaborationLabels,
    hubCasesLabels,
    hubCaseTasksLabels,
    platformActionLabels,
    platformNotesAndUploadsLabels,
    userDetailTitles,
    graphTypes,
    userAverageLabels,
    frequencyTypes,
    coachUserStatusLabels,
}
