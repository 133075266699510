import { Tooltip } from "@plinkrlabs/plinkr-ui"

const ConditionalToolTip = ({ label, children }) => label 
    ? <Tooltip label={label} delay={600} color={Tooltip.colors.SECONDARY}>
        {children}
    </Tooltip>
    : children

const RadioSwitch = ({ items, setSelected, selected, padding = 'px-3.5' }) => {
    const selectedIndex = items.findIndex(item => item.value === selected)
    const isNotFirstOrLastActive = selectedIndex > 0 && selectedIndex < items.length - 1

    return (
        <div className="flex items-center">
            {items?.map((item, index) => {
                const isNotFirstOrLast = index > 0 && index < items.length - 1

                return (
                    <ConditionalToolTip key={item.value} label={item.tooltip}>
                        <div
                            className={`
                                font-bold text-sm
                                border-y border-neutral-60
                                cursor-pointer select-none
                                transition-[color,background-color,opacity]
                                flex justify-center items-center
                                ${padding} min-w-[47px] h-[47px]

                                ${selected === item.value ? 'bg-white text-primary' : 'text-neutral-80 bg-neutral-40 opacity-60 hover:bg-primary-100 hover:opacity-100'}

                                ${index === 0 ? 'rounded-l-lg border-x' : ''}
                                ${index === items.length - 1 ? 'rounded-r-lg border-r' : ''}

                                ${isNotFirstOrLast ? 'border-r-2' : ''}

                                ${selected === item.value && isNotFirstOrLast ? 'border-x' : ''}

                                ${isNotFirstOrLastActive && index === selectedIndex - 1 ? 'border-r-0' : ''}
                                ${isNotFirstOrLastActive && index === selectedIndex + 1 ? 'border-l-0' : ''}

                                ${selectedIndex === items.length - 1 && index === selectedIndex - 1 ? 'border-r-0' : ''}
                                ${selectedIndex === items.length - 1 && index === selectedIndex ? 'border-l' : ''}
                            `} 
                            role="radio"
                            aria-checked={selected === item.value}
                            onClick={() => selected !== item.value && setSelected(item.value)}
                        >
                            { item.label || item.icon }
                        </div>
                    </ConditionalToolTip>
                )
            })}
        </div>
    )
}

export default RadioSwitch
