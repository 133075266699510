const dev = {
    platform: {
        apiId: 'shng2zxbhbhmlaxtehokd4i5fq',
        region: 'eu-west-1',
        auth: {
            type: 'AWS_IAM',
        },
    },
    hub: {
        apiId: 'wxjcn4o7orb4firdi7ipzz6eeq',
        region: 'eu-west-1',
        auth: {
            type: 'AWS_IAM',
        },
    }
}

const test = {
    platform: {
        apiId: 'my5zdbnvejhtfha5vgvqm4uvs4',
        region: 'eu-west-1',
        auth: {
            type: 'AWS_IAM',
        },
    },
    hub: {
        apiId: 'y6omljafuradlid7ziegnqnzwq',
        region: 'eu-west-1',
        auth: {
            type: 'AWS_IAM',
        },
    }
}

const prod = {
    platform: {
        apiId: 'bphbu2c7p5d4nbiprnk52quyey',
        region: 'eu-west-1',
        auth: {
            type: 'AWS_IAM',
        },
    },
    hub: {
        apiId: 'uhva2vq3bzekpe7bjwf27hh7ya',
        region: 'eu-west-1',
        auth: {
            type: 'AWS_IAM',
        },
    }
}

export const config = () => {
    switch (process.env.REACT_APP_HOST_ENV) {
        case 'prod':
            return prod
        case 'test':
            return test
        default:
            return dev
    }
}

