import { useField } from 'formik'
import _ from 'lodash'
import { Button, Checkbox, CheckboxSelect } from "@plinkrlabs/plinkr-ui"
import useMultiInput from '../../utils/hooks/useMultiInput'

const Cross = ({ onClick, size }) =>
    <svg onClick={onClick} className="cursor-pointer" width={size} height={size} viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
        <g id="Campaign-wizard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
            <g id="Layers" transform="translate(-740.000000, -433.000000)" stroke="currentColor" strokeWidth="3">
                <g id="Group" transform="translate(742.000000, 435.000000)">
                    <line x1="12.7592593" y1="0.240740741" x2="0.240740741" y2="12.7592593" id="Line"></line>
                    <line x1="0.240740741" y1="0.240740741" x2="12.7592593" y2="12.7592593" id="Line"></line>
                </g>
            </g>
        </g>
    </svg>

const Input = ({ name, placeholder, onChange, onBlur, type, value, ...props }) =>
    <input
        id={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        value={value}
        {...props}
        className={`
            rounded-lg transition ease-out duration-150 text-primary border border-neutral-60 h-[48px] placeholder-neutral-80
            focus:outline-none focus:border-primary w-full
            ${type !== 'color' && 'p-4'}
        `}
    />

const Select = ({ name, placeholder, onChange, onBlur, value, props, options }) => (
    <select
        id={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...props}
        className="
    rounded-lg transition ease-out duration-150 text-primary border border-neutral-60 h-[48px] w-full mt-2 pl-4 pr-6 placeholder-neutral-80
    focus:outline-none focus:border-primary"
    >
        {_.map(options, ({ value, label }) => <option key={value} value={value}>{label}</option>)}
    </select>
)

const Label = ({ label }) => <label className="flex flex-col mb-2 font-bold text-[14px]">{label}</label>

const MultiInputLabels = ({ activeValues, deleteValue }) =>
    <div className="flex flex-wrap gap-2 mb-4">
        {activeValues.map(item =>
            <div
                className="flex py-2 px-4 rounded-full items-center text-primary border border-primary"
                key={item}
            >
                <span className={`
                    mb-0 text-[12px] text-left font-bold block
                    ${deleteValue && 'mr-2'}
                `}>
                    {item}
                </span>
                {deleteValue && <Cross onClick={() => deleteValue(item)} size="10px" />}
            </div>)
        }
    </div>

const FormikMultiInput = ({ name }) => {
    const [, meta] = useField(name)
    const { value } = meta

    const [{ setInputValue, deleteValue, addValue }, inputValue] = useMultiInput(name)

    return (
        <div>
            {!_.isEmpty(value) && <MultiInputLabels activeValues={value} deleteValue={deleteValue} />}
            <div className="flex items-center">
                <Input id={name} onChange={({ target }) => setInputValue(target.value)} value={inputValue} />
                <Button label="Toevoegen" color={Button.colors.SECONDARY} padding={Button.paddings.LARGE} target={addValue} customStyle={{ marginLeft: 20, height: '48px', padding: '0 1.7em' }} />
            </div>
        </div>
    )
}

const FormikEnabledCheckbox = ({ name, props }) => {
    const [, meta, helpers] = useField(name)

    const { value } = meta
    const { setValue } = helpers

    return <div className="mr-3">
        <Checkbox scale="1em" isChecked={value} onClick={() => setValue(!value)} {...props} />
    </div>
}

const FormikEnabledCheckboxSelect = ({ name, options, props }) => {
    const [, meta, helpers] = useField(name)

    const { value } = meta
    const { setValue } = helpers

    return <div className="mt-1.5">
        <CheckboxSelect items={options} selected={JSON.parse(value)} onClick={e => setValue(JSON.stringify(e))} {...props} />
    </div>
}

const selectFormItem = ({ name, placeholder, type, value, options = [], onBlur, onChange, props }) => {
    switch (type) {
        case 'select':
            return <Select id={name} name={name} placeholder={placeholder} onChange={onChange} onBlur={onBlur} value={value} options={options} {...props} />
        case 'checkbox':
            return <FormikEnabledCheckbox name={name} props={props} />
        case 'multiSelect':
            return <FormikEnabledCheckboxSelect name={name} options={options} props={props} />
        case 'multiInput':
            return <FormikMultiInput name={name} props={props} />
        default:
            return <Input id={name} placeholder={placeholder} onChange={onChange} onBlur={onBlur} type={type} value={value} {...props} />
    }
}

const FormItem = (props) =>
    <div className="flex flex-col">
        <Label
            className={props.type === 'checkbox' ? 'flex-row-reverse' : 'flex-col w-full'}
            label={
                <div className="flex justify-between">
                    {props.label}
                    {props.optional && <span className="text-accent">optioneel</span>}
                </div>
            }
        />
        <div className="mb-4">{selectFormItem(props)}
            <span className="text-error text-sm">{props.error}</span>
        </div>
        {props.hasDivider && <div className="border-b-[1px] border-neutral-60 w-full mb-6" />}
    </div>

export {
    Input,
    FormItem
}
