import _ from 'lodash'

const subcategoryIds = {
    HEALTH_INSURANCE: '3001',
    GROCERIES: '11001',
    PROVISIONAL_ASSESSMENT: '8010',
    UNEXPECTED_BILLS: '12006'
}

const subcategories = [
    {
        "id": "1002",
        "value": "rent",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1001",
        "value": "mortgage",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1003",
        "value": "service_costs",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1014",
        "value": "owners_association_contribution",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1015",
        "value": "ground_lease",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1005",
        "value": "utilities",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1006",
        "value": "collective_heating",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1010",
        "value": "municipal_taxes",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1007",
        "value": "water_supply_company",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1011",
        "value": "water_board_levies",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1008",
        "value": "heating_boiler_maintenance",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1009",
        "value": "heating_boiler_rental",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1004",
        "value": "storage",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1012",
        "value": "security",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "1013",
        "value": "other_living_cost",
        "category": "housing",
        "budgetType": "expenses"
    },
    {
        "id": "2001",
        "value": "all_in_one",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": "2002",
        "value": "internet",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": "2008",
        "value": "television",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": "2003",
        "value": "mobile_phone",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": "2010",
        "value": "combined_subscription",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": "2004",
        "value": "mobile_phone_insurance",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": "2006",
        "value": "music_on_demand",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": "2007",
        "value": "video_on_demand",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": "2011",
        "value": "prepaid",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": "2005",
        "value": "landline",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": "2009",
        "value": "other_telecom",
        "category": "telecom",
        "budgetType": "expenses"
    },
    {
        "id": subcategoryIds.HEALTH_INSURANCE,
        "value": "health_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3002",
        "value": "liability_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3003",
        "value": "household_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3017",
        "value": "insurance_package",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3007",
        "value": "legal_expenses_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3008",
        "value": "annual_travel_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3016",
        "value": "car_liability_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3006",
        "value": "car_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3013",
        "value": "accident_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3009",
        "value": "motorcycle_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3010",
        "value": "moped_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3011",
        "value": "caravan_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3004",
        "value": "home_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "4004",
        "value": "endowment_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3014",
        "value": "disability_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3012",
        "value": "pet_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "4001",
        "value": "term_life_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "4002",
        "value": "funeral_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "4003",
        "value": "annuity_contract",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3005",
        "value": "valuables_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "3015",
        "value": "other_indemnity_insurance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "4005",
        "value": "other_lifeinsurrance",
        "category": "insurance",
        "budgetType": "expenses"
    },
    {
        "id": "5001",
        "value": "motor_vehicle_tax",
        "category": "transportation",
        "budgetType": "expenses"
    },
    {
        "id": "5002",
        "value": "private_lease",
        "category": "transportation",
        "budgetType": "expenses"
    },
    {
        "id": "5003",
        "value": "public_transport_subscription",
        "category": "transportation",
        "budgetType": "expenses"
    },
    {
        "id": "5007",
        "value": "bikesharing",
        "category": "transportation",
        "budgetType": "expenses"
    },
    {
        "id": "5008",
        "value": "carsharing",
        "category": "transportation",
        "budgetType": "expenses"
    },
    {
        "id": "5004",
        "value": "roadside_assistance",
        "category": "transportation",
        "budgetType": "expenses"
    },
    {
        "id": "5005",
        "value": "parking_permit",
        "category": "transportation",
        "budgetType": "expenses"
    },
    {
        "id": "5006",
        "value": "other_transportation_cost",
        "category": "transportation",
        "budgetType": "expenses"
    },
    {
        "id": "6001",
        "value": "membership_contribution",
        "category": "leisure",
        "budgetType": "expenses"
    },
    {
        "id": "6007",
        "value": "gym",
        "category": "leisure",
        "budgetType": "expenses"
    },
    {
        "id": "6002",
        "value": "newspaper_magazine",
        "category": "leisure",
        "budgetType": "expenses"
    },
    {
        "id": "6003",
        "value": "datingsite",
        "category": "leisure",
        "budgetType": "expenses"
    },
    {
        "id": "6004",
        "value": "online_service",
        "category": "leisure",
        "budgetType": "expenses"
    },
    {
        "id": "6005",
        "value": "going_out",
        "category": "leisure",
        "budgetType": "expenses"
    },
    {
        "id": "6006",
        "value": "other_freetime_cost",
        "category": "leisure",
        "budgetType": "expenses"
    },
    {
        "id": "7001",
        "value": "payment_account",
        "category": "finance",
        "budgetType": "expenses"
    },
    {
        "id": "7004",
        "value": "savings_account",
        "category": "finance",
        "budgetType": "expenses"
    },
    {
        "id": "7002",
        "value": "creditcard",
        "category": "finance",
        "budgetType": "expenses"
    },
    {
        "id": "7003",
        "value": "savings_deposit",
        "category": "finance",
        "budgetType": "expenses"
    },
    {
        "id": "7005",
        "value": "other_financial_costs",
        "category": "finance",
        "budgetType": "expenses"
    },
    {
        "id": "8001",
        "value": "mealbox",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8002",
        "value": "lottery",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8003",
        "value": "charity",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8004",
        "value": "childcare",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8005",
        "value": "education",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8012",
        "value": "course",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8013",
        "value": "hairdresser",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8014",
        "value": "personal_care",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8015",
        "value": "flowers_plants",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8006",
        "value": "appliance_rental",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8007",
        "value": "alimony_payment",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8008",
        "value": "own_contribution_healthcare",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8009",
        "value": "domestic_help",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": subcategoryIds.PROVISIONAL_ASSESSMENT,
        "value": "provisional_assessment",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "8011",
        "value": "other_fixed_cost",
        "category": "other",
        "budgetType": "expenses"
    },
    {
        "id": "9001",
        "value": "consumer_credit",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9002",
        "value": "personal_loan",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9003",
        "value": "creditcard_spread",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9004",
        "value": "overdraft",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9005",
        "value": "mail_order_credit",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9006",
        "value": "benefit_received_unduly",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9007",
        "value": "tax_debt",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9008",
        "value": "fine_frauddebt",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9009",
        "value": "debt_with_family_or_friend",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9010",
        "value": "student_debt",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9011",
        "value": "other_loan",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "9012",
        "value": "restructuring_credit",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "loans"
    },
    {
        "id": "10001",
        "value": "mortgage_arrears",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "arrears"
    },
    {
        "id": "10002",
        "value": "rent_arrears",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "arrears"
    },
    {
        "id": "10003",
        "value": "healthcare_arrears",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "arrears"
    },
    {
        "id": "10004",
        "value": "fixed_cost_arrears",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "arrears"
    },
    {
        "id": "10006",
        "value": "student_debt_arrears",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "arrears"
    },
    {
        "id": "10007",
        "value": "other_arrears",
        "category": "debts",
        "budgetType": "expenses",
        "debtType": "arrears"
    },
    {
        "id": subcategoryIds.GROCERIES,
        "value": "groceries",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11004",
        "value": "clothing",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11008",
        "value": "leisure_expenses",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11002",
        "value": "smoking",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11010",
        "value": "leisure_budgets",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11011",
        "value": "hobbies",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11012",
        "value": "gifts",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11006",
        "value": "fuel",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11007",
        "value": "vehicle",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11003",
        "value": "pets",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11005",
        "value": "uncovered_medical_expenses",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "11009",
        "value": "other_budgets",
        "category": "budgets",
        "budgetType": "expenses"
    },
    {
        "id": "12001",
        "value": "deductible_excess_health_insurance",
        "category": "reservations",
        "budgetType": "expenses"
    },
    {
        "id": subcategoryIds.UNEXPECTED_BILLS,
        "value": "unexpected_bills",
        "category": "reservations",
        "budgetType": "expenses"
    },
    {
        "id": "12007",
        "value": "holiday",
        "category": "reservations",
        "budgetType": "expenses"
    },
    {
        "id": "12002",
        "value": "inventory",
        "category": "reservations",
        "budgetType": "expenses"
    },
    {
        "id": "12005",
        "value": "house_garden_maintenance",
        "category": "reservations",
        "budgetType": "expenses"
    },
    {
        "id": "12003",
        "value": "car_maintenance",
        "category": "reservations",
        "budgetType": "expenses"
    },
    {
        "id": "12004",
        "value": "car_replacement",
        "category": "reservations",
        "budgetType": "expenses"
    },
    {
        "id": "12008",
        "value": "other_reservations",
        "category": "reservations",
        "budgetType": "expenses"
    },
    {
        "id": "13001",
        "value": "salary",
        "category": "income",
        "budgetType": "earnings"
    },
    {
        "id": "13002",
        "value": "welfare",
        "category": "income",
        "budgetType": "earnings"
    },
    {
        "id": "13008",
        "value": "variable_income",
        "category": "income",
        "budgetType": "earnings"
    },
    {
        "id": "13006",
        "value": "additional_income",
        "category": "income",
        "budgetType": "earnings"
    },
    {
        "id": "13009",
        "value": "holiday_pay",
        "category": "income",
        "budgetType": "earnings"
    },
    {
        "id": "13005",
        "value": "student_grant",
        "category": "income",
        "budgetType": "earnings"
    },
    {
        "id": "13004",
        "value": "pension",
        "category": "income",
        "budgetType": "earnings"
    },
    {
        "id": "13003",
        "value": "operating_profits",
        "category": "income",
        "budgetType": "earnings"
    },
    {
        "id": "13007",
        "value": "other_income",
        "category": "income",
        "budgetType": "earnings"
    },
    {
        "id": "14001",
        "value": "housing_benefit",
        "category": "benefit",
        "budgetType": "earnings"
    },
    {
        "id": "14002",
        "value": "healthcare_benefit",
        "category": "benefit",
        "budgetType": "earnings"
    },
    {
        "id": "14006",
        "value": "municipal_agreement",
        "category": "benefit",
        "budgetType": "earnings"
    },
    {
        "id": "14003",
        "value": "child_benefit",
        "category": "benefit",
        "budgetType": "earnings"
    },
    {
        "id": "14004",
        "value": "childcare_benefit",
        "category": "benefit",
        "budgetType": "earnings"
    },
    {
        "id": "14005",
        "value": "child_budget",
        "category": "benefit",
        "budgetType": "earnings"
    },
    {
        "id": "14007",
        "value": "other_benefit",
        "category": "benefit",
        "budgetType": "earnings"
    },
    {
        "id": "15001",
        "value": "alimony",
        "category": "other",
        "budgetType": "earnings"
    },
    {
        "id": "15002",
        "value": "tax_rebate",
        "category": "other",
        "budgetType": "earnings"
    },
    {
        "id": "15003",
        "value": "other_earnings",
        "category": "other",
        "budgetType": "earnings"
    }
]

const getSubcategory = id => _.find(subcategories, sub => _.get(sub, 'id') === id)?.value

export {
    getSubcategory,
    subcategories,
}
