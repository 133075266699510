import { dataType, platformClientOnboardingLabels } from "../../config/constants"
import AnalyticsCard from "../AnalyticsCard"
import { LineChart, PieChart } from "../charts"
import DataSection from "./DataSection"

const ClientOnboarding = ({ title, data = [], loading, setting, set, onDetailClick, isDetailPage }) => {
    const parsedData = [], parsedLabels = []

    if (setting === dataType.SNAPSHOT) {
        data[0] && Object.keys(platformClientOnboardingLabels).forEach(key => {
            parsedData.push(data[0][key])
            parsedLabels.push(platformClientOnboardingLabels[key])
        })
    } else {
        const allowedTypes = Object.keys(platformClientOnboardingLabels)
        const pairedData = {}

        data.forEach(item => {
            allowedTypes.forEach(type => {
                if (pairedData[type]) {
                    pairedData[type].push([item.timestamp, item[type]])
                } else {
                    pairedData[type] = [[item.timestamp, item[type]]]
                }
            })
        })
        Object.keys(pairedData).forEach(key => parsedData.push({ name: platformClientOnboardingLabels[key], data: pairedData[key] }))
    }

    return isDetailPage
        ? <LineChart data={parsedData} height={500} />
        : <AnalyticsCard
            isEmpty={data.length === 0}
            isLoading={loading}
            title={title}
            amount={setting === dataType.SNAPSHOT ? data[0]?.task_zero_completed_count + data[0]?.task_gt0_lte5_completed_count + data[0]?.task_gt5_completed_acount : 0}
            selectedDataType={setting}
            setSelectedDataType={set}
            onDetailClick={onDetailClick}
        >
            {setting === dataType.SNAPSHOT
                ? <>
                    <PieChart data={parsedData} labels={parsedLabels} legendTitle="Gebruikers met" />
                    <div className="mt-2">
                        <DataSection label="Gemiddeld afgeronde stappen per gebruiker" data={data[0]?.task_completed_acount} />
                    </div>
                </>
                : <LineChart data={parsedData} labels={parsedLabels} />
            }
        </AnalyticsCard>
}

export default ClientOnboarding
