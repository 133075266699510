import { dataType, userAverageLabels } from "../../config/constants"
import AnalyticsCard from "../AnalyticsCard"
import { LineChart } from "../charts"
import DataSection from "./DataSection"

const Subtitle = ({ label }) => <h2 className="text-[14px] text-neutral-80 mb-2">{ label }</h2>
const Flex = ({ children }) => <div className="grid grid-cols-3">{ children }</div>

const LineChartBlock = ({label, isDetailPage, ...props}) => 
    <div className="flex flex-col gap-4 pr-6 [&:not(:first-child)]:pt-6 [&:not(:last-child)]:pb-6 [&:not(:last-child)]:border-b border-neutral-60">
        <Subtitle label={label} />
        <LineChart {...props} height={isDetailPage ? 300 : 180} />
    </div>

const UserAverage = ({ setting, set, data = [], loading, onDetailClick, isDetailPage }) => {
    let budgetGraph = [], budgetItemGraph = [], debtItemGraph = []

    if (setting === dataType.RANGE && data.length) {
        const allowedTypes = Object.keys(userAverageLabels)
        const pairedData = {}

        data.forEach(item => {
            allowedTypes.forEach(type => {
                if (pairedData[type]) {
                    pairedData[type].push([item.timestamp, parseFloat(item[type]).toFixed(2)])
                } else {
                    pairedData[type] = [[item.timestamp, parseFloat(item[type]).toFixed(2)]]
                }
            })
        })

        budgetItemGraph = [
            { name: userAverageLabels.earning_avg_count, data: pairedData.earning_avg_count },
            { name: userAverageLabels.expense_avg_count, data: pairedData.expense_avg_count }
        ]
        budgetGraph = [
            { name: userAverageLabels.earning_avg_sum, data: pairedData.earning_avg_sum.map(item => [item[0], (item[1] / 100)]) },
            { name: userAverageLabels.expense_avg_sum, data: pairedData.expense_avg_sum.map(item => [item[0], (item[1] / 100)]) }
        ]
        debtItemGraph = [
            { name: userAverageLabels.debt_avg_count, data: pairedData.debt_avg_count },
            { name: userAverageLabels.arrears_avg_count, data: pairedData.arrears_avg_count },
            { name: userAverageLabels.debt_has_collector_avg_count, data: pairedData.debt_has_collector_avg_count }
        ]
    }

    return isDetailPage
        ? <>
            <LineChartBlock data={budgetItemGraph} label="Budget items inkomsten en uitgaven" isDetailPage />
            <LineChartBlock data={budgetGraph} label="Inkomsten en uitgaven" yLabel="€" isDetailPage />
            <LineChartBlock data={debtItemGraph} label="Budget items schulden" isDetailPage />
        </>
        : <AnalyticsCard
            title="Gemiddeldes per gebruiker"
            selectedDataType={setting}
            setSelectedDataType={set}
            isEmpty={data.length === 0}
            isLoading={loading}
            onDetailClick={onDetailClick}
        >
            {setting === dataType.SNAPSHOT && <>
                <div className="flex flex-col gap-4 pr-6">
                    <Subtitle label="Inkomsten en uitgaven" />
                    <Flex>
                        <DataSection label={userAverageLabels.earning_avg_count} data={data[0]?.earning_avg_count} />
                        <DataSection label={userAverageLabels.earning_avg_sum} data={data[0]?.earning_avg_sum} isCurrency />
                    </Flex>
                    <Flex>
                        <DataSection label={userAverageLabels.expense_avg_count} data={data[0]?.expense_avg_count} />
                        <DataSection label={userAverageLabels.expense_avg_sum} data={data[0]?.expense_avg_sum} isCurrency />
                        <DataSection label="saldo" data={data[0]?.earning_avg_sum - data[0]?.expense_avg_sum} isCurrency />
                    </Flex>
                </div>
                <div className="flex flex-col gap-4 pr-6 pt-6 mt-6 border-t border-neutral-60"> {/* py-6 border-y */}
                    <Subtitle label="Schulden" />
                    <Flex>
                        <DataSection label={userAverageLabels.debt_avg_count} data={data[0]?.debt_avg_count} />
                        <DataSection label={userAverageLabels.arrears_avg_count} data={data[0]?.arrears_avg_count} />
                        <DataSection label={userAverageLabels.debt_has_collector_avg_count} data={data[0]?.debt_has_collector_avg_count} />
                    </Flex>
                    <Flex>
                        <div />
                        <div />
                        <DataSection label="som aan schulden" data={data[0]?.debt_avg_sum} isCurrency />
                    </Flex>
                </div>
                {/* <Flex>
                    <DataSection label="notities" data="3,2" />
                    <DataSection label="uploads" data="7,4" />
                    <DataSection label="afgeronde acties" data="12,1" />
                </Flex> */}
            </>}
            {setting === dataType.RANGE && <>
                <LineChartBlock data={budgetItemGraph} label="Budget items inkomsten en uitgaven"/>
                <LineChartBlock data={budgetGraph} label="Inkomsten en uitgaven" yLabel="€"/>
                <LineChartBlock data={debtItemGraph} label="Budget items schulden" />
            </>}
        </AnalyticsCard>
}

export default UserAverage
