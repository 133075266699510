import { Button, Calendar, Popover } from "@plinkrlabs/plinkr-ui"
import { range } from "lodash"
import { useEffect, useRef, useState } from "react"

const toISO = date => {
    const year = date.getFullYear()
    let month = date.getMonth() + 1, dt = date.getDate()

    if (dt < 10) dt = '0' + dt

    if (month < 10) month = '0' + month

    return year + '-' + month + '-' + dt
}

const ChevronIcon = svgProps =>
    <svg aria-hidden="true" width="7px" height="12px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
        <path stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd" d="M7.97425797,9.23489474 C8.3233448,9.58836842 8.8898449,9.58836842 9.23893172,9.23489474 C9.58702276,8.88040988 9.58702276,8.30514982 9.23893172,7.95066496 L4.13233687,2.76510526 C3.78325005,2.41163158 3.21674995,2.41163158 2.86766313,2.76510526 L-2.23893172,7.95066496 C-2.58702276,8.30514982 -2.58702276,8.88040988 -2.23893172,9.23489474 C-1.8898449,9.58836842 -1.3233448,9.58836842 -0.974257975,9.23489474 L3.50192774,4.68845528 L7.97425797,9.23489474 Z" id="Fill-1-Copy" transform="translate(3.500000, 6.000000) rotate(90.000000) translate(-3.500000, -6.000000) "></path>
    </svg>

const RangeOption = ({ children, ...props }) => <span className="select-none cursor-pointer text-primary transition-colors hover:text-primary-400" {...props}>{children}</span>

const DateRangeSelector = ({ icon, start, end, onChange, showClearButton }) => {
    const popoverRef = useRef()
    const [dates, setDates] = useState({ start, end })
    const minDate = new Date('2020-01-01'), maxDate = new Date()

    const handleChange = (key, val) => {
        const newState = typeof key === 'object' ? {...dates, ...key} : {...dates, [key]: val}
        setDates(newState)
        onChange(newState.start, newState.end)  
    }

    const handleWeek = offset => {
        const curr = new Date()
        const first = curr.getDate() - curr.getDay() + 1
        const last = first + 6

        let startDate = new Date(curr.setDate(first))
        let endDate = new Date(curr.setDate(last))

        if (offset) {
            startDate.setDate(startDate.getDate() - offset)
            endDate.setDate(endDate.getDate() - offset)
        }

        if (startDate < minDate) startDate = minDate
        if (endDate > maxDate) endDate = maxDate

        handleChange({ start: toISO(startDate), end: toISO(endDate) })
    }

    const handleMonth = offset => {
        const curr = new Date()
        const year = curr.getFullYear()
        const month = curr.getMonth()

        let startDate = new Date(year, month - (offset || 0), 1)
        let endDate = new Date(year, month + 1 - (offset || 0), 0)

        if (startDate < minDate) startDate = minDate
        if (endDate > maxDate) endDate = maxDate

        handleChange({ start: toISO(startDate), end: toISO(endDate) })
    }

    const handleYear = offset => {
        const curr = new Date()
        let startDate = new Date(curr.getFullYear() - (offset || 0), 0, 1)
        let endDate = new Date(curr.getFullYear() - (offset || 0), 11, 31)

        if (startDate < minDate) startDate = minDate
        if (endDate > maxDate) endDate = maxDate

        handleChange({ start: toISO(startDate), end: toISO(endDate) })
    }

    const resetDates = () => {
        setDates({ start: null, end: null })
        onChange(null, null)
        popoverRef.current.setIsOpen(false)
    }

    useEffect(() => setDates({ start, end }), [start, end])

    return (
        <div className="w-max">
            <Popover
                ref={popoverRef}
                childPadding="p-0"
                width="max-content"
                align={Popover.alignments.BOTTOMRIGHT}
                toggler={(setOpen, isOpen) => <Button
                    icon={icon}
                    color={`border text-primary hover:bg-primary-100 ${isOpen ? 'border-primary' : 'border-neutral-60'}`}
                    padding={icon && 'p-[12px_24px_12px_18px]'}
                    onClick={() => setOpen(!isOpen)} label={dates.start && dates.end 
                        ? `${new Date(dates.start).toLocaleDateString('nl', { year: 'numeric', month: 'numeric', day: 'numeric' })} / ${new Date(dates.end).toLocaleDateString('nl', { year: 'numeric', month: 'numeric', day: 'numeric' })}`
                        : 'Selecteer periode'
                    }
                />}
            >
                <div className="grid grid-cols-[1fr_auto]">
                    <div>
                        <div className="grid grid-cols-2 justify-center items-center text-center p-4 gap-8 border-b border-b-neutral-60 relative">
                            <span>{dates.start ? new Date(dates.start).toLocaleDateString('nl', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Startdatum'}</span>
                            <span>{dates.end ? new Date(dates.end).toLocaleDateString('nl', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Einddatum'}</span>
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><ChevronIcon/></div>
                        </div>
                        <div className="grid grid-cols-2 [&>div>div:first-child]:border-[0px] [&>div:first-child]:border-r-neutral-60 [&>div:first-child]:border-r">
                            <Calendar minDate={minDate.toISOString()} maxDate={maxDate.toISOString()} date={dates.start} onSelect={v => handleChange('start', v)}/>
                            <Calendar minDate={minDate.toISOString()} maxDate={maxDate.toISOString()} date={dates.end} onSelect={v => handleChange('end', v)}/>
                        </div>
                    </div>
                    <div className="border-l border-neutral-60">
                        {showClearButton && <div className="p-4 text-center border-b border-neutral-60 select-none cursor-pointer text-primary transition-colors hover:text-primary-400" onClick={() => resetDates()}>
                            Wissen
                        </div>}
                        <div className={`flex flex-col gap-4 p-4 h-full overflow-y-auto ${showClearButton ? 'max-h-[280px]' : 'max-h-[340px]'}`}>
                            <RangeOption onClick={() => handleWeek()}>This week</RangeOption>
                            <RangeOption onClick={() => handleWeek(7)}>Last week</RangeOption>
                            <RangeOption onClick={() => handleMonth()}>This month</RangeOption>
                            <RangeOption onClick={() => handleMonth(1)}>Last month</RangeOption>
                            <RangeOption onClick={() => handleYear()}>This year</RangeOption>
                            {range(maxDate.getFullYear(), minDate.getFullYear()).map(year => <RangeOption key={year} onClick={() => handleYear(maxDate.getFullYear() - year + 1)}>{year - 1}</RangeOption>)}
                        </div>  
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default DateRangeSelector