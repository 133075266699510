import { Tooltip } from "@plinkrlabs/plinkr-ui"

const DetailIcon = () =>
    <svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.41046 19.2562L1.08337 18.2812L6.17504 10.1562L9.42504 13.9479L13.7313 6.96041L16.6834 11.3479C16.3945 11.384 16.1146 11.4427 15.8438 11.5239C15.573 11.6052 15.3021 11.7 15.0313 11.8083L13.8125 9.93957L9.66879 16.6833L6.39171 12.8646L2.41046 19.2562ZM23.7521 24.9167L20.123 21.2875C19.7438 21.5583 19.333 21.766 18.8907 21.9104C18.4483 22.0549 17.9924 22.1271 17.523 22.1271C16.241 22.1271 15.1532 21.6802 14.2594 20.7864C13.3657 19.8927 12.9188 18.8049 12.9188 17.5229C12.9188 16.241 13.3657 15.1531 14.2594 14.2594C15.1532 13.3656 16.241 12.9187 17.523 12.9187C18.8049 12.9187 19.8927 13.3656 20.7865 14.2594C21.6803 15.1531 22.1271 16.241 22.1271 17.5229C22.1271 17.9924 22.0504 18.4483 21.8969 18.8906C21.7434 19.333 21.5403 19.7528 21.2875 20.15L24.9167 23.7521L23.7521 24.9167ZM17.523 20.5021C18.3535 20.5021 19.0577 20.2132 19.6355 19.6354C20.2132 19.0576 20.5021 18.3535 20.5021 17.5229C20.5021 16.6924 20.2132 15.9882 19.6355 15.4104C19.0577 14.8326 18.3535 14.5437 17.523 14.5437C16.6924 14.5437 15.9882 14.8326 15.4105 15.4104C14.8327 15.9882 14.5438 16.6924 14.5438 17.5229C14.5438 18.3535 14.8327 19.0576 15.4105 19.6354C15.9882 20.2132 16.6924 20.5021 17.523 20.5021ZM19.5271 11.6187C19.2563 11.5104 18.9764 11.4382 18.6875 11.4021C18.3987 11.366 18.1007 11.3299 17.7938 11.2937L23.5896 2.16666L24.9167 3.14166L19.5271 11.6187Z" fill="currentColor" />
    </svg>

const icons = {
    detail: <DetailIcon />
}

const IconButton = ({ label = 'Bekijk details', icon = icons.detail, onClick }) => 
    <Tooltip label={label} delay={1000} color={Tooltip.colors.SECONDARY}>
        <div onClick={onClick} className="border border-neutral-60 flex justify-center items-center w-[47px] h-[47px] rounded-lg cursor-pointer hover:bg-primary-100">
            { icon }
        </div>
    </Tooltip>

IconButton.icons = icons

export default IconButton
