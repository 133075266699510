import { hubCasesLabels } from "../../config/constants"
import { LineChart } from "../charts"

const Cases = ({ data = [] }) => {
    let parsedData = []

    if (data.length) {
        const allowedTypes = [
            'project_active_count',
            'project_pending_count',
            'project_completed_count',
        ]
        const pairedData = {}

        data.forEach(item => {
            allowedTypes.forEach(type => {
                if (pairedData[type]) {
                    pairedData[type].push([item.timestamp * 1000, Math.ceil(item[type])])
                } else {
                    pairedData[type] = [[item.timestamp * 1000, Math.ceil(item[type])]]
                }
            })
        })

        Object.keys(pairedData).forEach(key => parsedData.push({ name: hubCasesLabels[key], data: pairedData[key] }))
    }

    return <LineChart data={parsedData} height={500} />
}

export default Cases
