import { useRef, useEffect, useState } from 'react'
import { Button, Wrapper, Icon, Toggler, Modal, Tooltip } from '@plinkrlabs/plinkr-ui'
import toast from 'react-hot-toast'
import _ from 'lodash'
import LoadingWrapper from '../components/generic/LoadingWrapper'
import { useLocalized } from '../config/localized-context'
import { useNavigate, useParams } from 'react-router-dom'
import useCompanies from '../utils/hooks/useCompanies'
import FormHandler from '../components/forms/FormHandler'
import { Input } from '../components/forms/FormElements'

import { ReactComponent as Arrow } from '../assets/svg/arrow.svg'
import { ReactComponent as Edit } from '../assets/svg/edit.svg'

const EditButton = ({ label, onClick }) =>
    <div className="absolute top-[24px] right-[24px]">
        <Tooltip label={label}>
            <div className={`
                border-[1px] bg-neutral-20 rounded-md w-[42px] h-[42px] cursor-pointer
                flex justify-center items-center
                hover:bg-primary-100
                transition-colors duration-500
            `} onClick={onClick}>
                <Edit/>
            </div>
        </Tooltip>
    </div>

const DetailBlock = ({ icon, title, description, items, data, tooltip, formType, onSubmit }) => {
    const modalRef = useRef()

    return (
        <Wrapper width="100%" theme={Wrapper.themes.CARD} extraClass="grid grid-cols-[290px_auto] gap-12 relative">
            <div className="flex flex-col">
                {icon}
                <span className="text-xl font-bold mt-[20px] mb-[10px]">{title}</span>
                <span className="text-sm">{description}</span>
            </div>
            <div className="grid grid-cols-2 gap-5">
                {_.map(items, item => <div key={item.label} className="flex flex-col gap-1">
                    <span className="text-sm">{item.label}</span>
                    <span className="font-bold">{item.value}</span>
                </div>)}

                <Toggler
                    toggler={open => <EditButton onClick={open} label={tooltip}/>}
                    content={close =>
                        <Modal
                            ref={modalRef}
                            closeHandler={close}
                            title={tooltip}>
                            <FormHandler
                                data={data}
                                onSubmit={e => onSubmit(e).then(modalRef.current.close)}
                                type={formType} />
                    </Modal>
                    }
                />
            </div>
        </Wrapper>
    )
}

const CompanyDetail = () => {
    const modalRef = useRef()
    const { company, isLoading, updateCompany, getCompany, deleteCompany } = useCompanies()
    const { id } = useParams()
    const navigate = useNavigate()
    const { localized } = useLocalized()
    const [deleteMatch, setDeleteMatch] = useState(false)

    const handleUpdateCompany = values =>
        updateCompany(values, id)
            .then(() => toast.success(localized('toast_company_edited')))
            .catch(() => toast.error(localized('toast_general_error')))

    const handleDeleteCompany = () =>
        deleteCompany(id)
            .then(() => navigate('/companies'))
            .then(() => toast.success(localized('toast_company_deleted')))
            .catch(() => toast.error(localized('toast_general_error')))

    useEffect(() => {
        if (id && (_.isEmpty(company) || company.id !== id)) {
            getCompany(id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <div className="flex flex-col gap-9 p-9">
            {/* Heading */}
            <div className="flex gap-4">
                <Button color={Button.colors.PRIMARY_INVERSED} shape={Button.shapes.ICON} padding={Button.paddings.NONE} label={<div className="rotate-180 scale-[.8]"><Arrow style={{ filter: 'rotate(180) scale(0.5)' }} /></div>} target={() => navigate(-1)}/>

                <LoadingWrapper isLoading={isLoading} containerClass="flex justify-between w-full">
                    <h1 className="text-primary text-[1.75rem] font-ALSSchlangeslabBold">{company?.name}</h1>
                    <Toggler
                        container={document.body}
                        toggler={open => <Button target={open} label={localized('delete_company')} color={Button.colors.SECONDARY} />}
                        content={close =>
                            <Modal title={localized('delete_company')} ref={modalRef} closeHandler={close}>
                                <div className="flex flex-col gap-5">
                                    <span className="whitespace-pre-line">
                                        {localized('delete_company_detail')}
                                    </span>

                                    <Input
                                        name='name' label={localized('company_name')} placeholder={_.get(company, 'name')}
                                        onChange={({ target }) => setDeleteMatch(_.get(company, 'name') === _.get(target, 'value'))}
                                    />

                                    <div className="flex justify-end gap-5">
                                        <Button target={() => modalRef.current.close()} label={localized('cancel')} color={Button.colors.PRIMARY_INVERSED} />
                                        <Button target={handleDeleteCompany} label={localized('delete')} color={Button.colors.SECONDARY} isLoading={isLoading} isDisabled={!deleteMatch} />
                                    </div>
                                </div>
                        </Modal>
                        }
                    />
               </LoadingWrapper>
            </div>

            {/* Content */}
            <LoadingWrapper isLoading={isLoading} containerClass="flex flex-col gap-[50px]">
                <DetailBlock
                    icon={<Icon name="municipal_taxes" type="svg" size={Icon.sizes.MEDIUM} />}
                    title={localized('title_company')}
                    tooltip={localized('description_company')}
                    editLabel={localized('edit_company')}
                    buttonLabel={localized('save_changes')}
                    items={[
                        { label: localized('company_name'), value: <div className="flex flex-col gap-1">
                            {company.name}
                            {company.alternative_names && <span className="text-xs text-primary-400">
                                {localized('extra_company_names')}:
                                <br/>
                                <span className="font-light">{company.alternative_names.join(', ')}</span>
                            </span>}
                        </div> },
                        { label: localized('company_type'), value: company.kind || '-' },
                        { label: localized('created_at'), value: company.created_at || '-' },
                        { label: localized('coc_number'), value: company.coc_number || '-' },
                    ]}
                    data={company}
                    formType="edit_company_general"
                    onSubmit={values => handleUpdateCompany(values)}
                />

                <DetailBlock
                    icon={<Icon name="rent" type="svg" size={Icon.sizes.MEDIUM} />}
                    title={localized('title_address')}
                    description={localized('description_address')}
                    tooltip={localized('edit_company_address')}
                    buttonLabel={localized('save_changes')}
                    items={[
                        { label: localized('address'), value: company.address
                            ? <div className="flex flex-col gap-1">
                                <span>{company.address.street} {company.address.number} {company.address.addition}</span>
                                <span>{company.address.postal} {company.address.city}</span>
                            </div>
                            : '-'
                        },
                    ]}
                    data={company.address}
                    formType="edit_company_address"
                    onSubmit={values => handleUpdateCompany(values)}
                />

                <DetailBlock
                    icon={<Icon name="help" type="svg" size={Icon.sizes.MEDIUM} />}
                    title={localized('title_contact')}
                    description={localized('description_contact')}
                    tooltip={localized('edit_company_contact')}
                    buttonLabel={localized('save_changes')}
                    items={[
                        { label: localized('email'), value: company.email || '-' },
                        { label: localized('website'), value: company.website || '-' },
                        { label: localized('phone_number'), value: company.phone || '-' },
                    ]}
                    data={company}
                    formType="edit_company_contact"
                    onSubmit={values => handleUpdateCompany(values)}
                />
            </LoadingWrapper>
        </div>
    )
}

export default CompanyDetail