import { MenuItem } from '@plinkrlabs/plinkr-ui'
import { useLocation, useNavigate } from 'react-router-dom'

const Menu = ({ items }) => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <div className="w-full flex-1">
            { items.map(item => <MenuItem key={item.path} label={item.title} onClick={() => navigate(item.path)} isActive={location.pathname.includes(item.path)} />) }
        </div>
    )
}

export default Menu