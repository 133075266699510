import Analytics from '../pages/Analytics'
import AnalyticDetail from '../pages/AnalyticDetail'
import Companies from '../pages/Companies'
import CompanyDetail from '../pages/CompanyDetail'
import Articles from '../pages/Articles'

export const routeTypes = {
    MAILS_PATH: '/mails',
    MAIL_DETAIL_PATH: '/mails/:id',
    PUSH_PATH: '/push',
    CREATE_CAMPAIGN: '/create-campaign',
    ANALYTICS_PATH: '/analytics',
    ANALYTIC_DETAIL_PATH: '/analytics/:id',
    COMPANIES_PATH: '/companies',
    COMPANY_DETAIL_PATH: '/companies/:id',
    ARTICLES_PATH: '/articles',
    ARTICLE_DETAIL_PATH: '/articles/:id',
}

// const MailDetailPageWrapper = ({ navigate }) => {
//     const { id } = useParams()
//
//     return (
//         <MailDetailPage
//             id={id}
//             onGoBack={() => navigate('/mails')}
//             onEmailSaveSuccess={() => toast.success('E-mail opgeslagen')}
//             onEmailSaveError={() => toast.error('Er is iets misgegaan')}
//             onEditCancelSuccess={() => toast.success('Wijzigingen geannuleerd')}
//         />
//     )
// }
//
// const WizardWrapperPage = ({ onClose }) => {
//     const [, , content] = useCreateCampaignWizard()
//
//     return <Wizard content={content} onClose={onClose} />
// }

const useRoutes = () => {

    return [
        // {
        //     path: routeTypes.MAILS_PATH,
        //     exact: true,
        //     menuItem: true,
        //     title: 'Mails',
        //     element: <MailListPage
        //         onEdit={item => navigate(`/mails/${_.get(item, 'name')}`)}
        //         onCreateCampaign={() => navigate('/create-campaign')}
        //         onInvalidTestEmail={() => toast.error('Ongeldig e-mailadres')}
        //         onMessageSentSuccess={() => toast.success('Mail verzonden!')}
        //     />
        // },
        // {
        //     path: routeTypes.MAIL_DETAIL_PATH,
        //     exact: true,
        //     menuItem: false,
        //     element: <MailDetailPageWrapper navigate={navigate} />,
        // },
        // {
        //     path: routeTypes.PUSH_PATH,
        //     exact: true,
        //     menuItem: true,
        //     title: 'Push',
        //     element:
        //         <PushOverviewPage
        //             onMessageSentSuccess={() => toast.success('Push bericht verzonden!')}
        //             onGeneralError={() => toast.error('Er is iets misgegaan')}
        //         />
        // },
        // {
        //     path: routeTypes.CREATE_CAMPAIGN,
        //     exact: true,
        //     menuItem: false,
        //     element: <WizardWrapperPage onClose={() => navigate('/mails?tab=campaigns')} />,
        //     icon: <> </>
        // },
        {
            path: routeTypes.ANALYTICS_PATH,
            exact: true,
            menuItem: true,
            title: 'Analytics',
            element: <Analytics/>
        },
        {
            path: routeTypes.ANALYTIC_DETAIL_PATH,
            exact: true,
            element: <AnalyticDetail/>
        },
        {
            path: routeTypes.COMPANIES_PATH,
            exact: true,
            menuItem: true,
            title: 'Companies',
            element: <Companies/>
        },
        {
            path: routeTypes.COMPANY_DETAIL_PATH,
            exact: true,
            element: <CompanyDetail/>
        },
        {
            path: routeTypes.ARTICLES_PATH,
            exact: true,
            menuItem: true,
            title: 'Artikelen',
            element: <Articles/>
        },
        {
            path: routeTypes.ARTICLE_DETAIL_PATH,
            exact: true,
            element: <CompanyDetail/>
        },
    ]
}

export default useRoutes
