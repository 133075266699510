import { dataType, stressFormLabels, userTypes } from "../../config/constants"
import { rangeToApex, snapshotToApex } from "../../utils/helper"
import AnalyticsCard from "../AnalyticsCard"
import { LineChart, PieChart } from "../charts"

const Surveys = ({ title, data = [], loading, setting, set, userType, onDetailClick, isDetailPage }) => {
    const parsedUserType = userType === userTypes.CLIENT ? userType : 'professional'

    const rangeData = {...rangeToApex(
        data, 
        stressFormLabels,
        'user_type', 
        parsedUserType, 
    )}

    const snapshotData = {...snapshotToApex(
        data, 
        stressFormLabels,
        'user_type', 
        parsedUserType, 
    )}

    return isDetailPage
        ? <LineChart height={500} {...rangeData} />
        : <AnalyticsCard
            isEmpty={data.length === 0}
            isLoading={loading}
            title={title}
            amount={setting === dataType.SNAPSHOT ? data?.filter(item => item.user_type === parsedUserType).reduce((acc, item) => acc + +item.count, 0) : 0}
            selectedDataType={setting}
            setSelectedDataType={set}
            onDetailClick={onDetailClick}
        >
            {setting === dataType.SNAPSHOT
                ? <PieChart {...snapshotData} />
                : <LineChart {...rangeData} />
            }
        </AnalyticsCard>
}

export default Surveys
