
import { Button, Table, Modal, Toggler, TableActions } from '@plinkrlabs/plinkr-ui'
import LoadingWrapper from '../components/generic/LoadingWrapper'
import { useLocalized } from '../config/localized-context'
import toast from "react-hot-toast"
import FormHandler from '../components/forms/FormHandler'

import useArticles from '../utils/hooks/useArticles'
import _ from 'lodash'
import { useRef } from 'react'
import { useState } from 'react'
import ReactDOM from 'react-dom'

/**
 * Flatten an article object for usage in FormHandler
 *
 * TODO: formik should be able to handle nested values with dot notation (image.url), but has very
 * unexpected results
 */
const flattenArticle = article => _.pickBy({
    ..._.pick(article, ['id', 'title', 'sub_title', 'label', 'url']),
    image_url: _.get(article, 'image.url'),
    image_color: _.get(article, 'image.color')
})

const Articles = () => {
    const { localized } = useLocalized()
    const [articles, { createArticle, updateArticle, deleteArticle }, isLoading] = useArticles()
    const [selectedArticle, setSelectedArticle] = useState()
    const [modalType, setModalType] = useState()
    const createArticleModalRef = useRef()
    const editArticleModalRef = useRef()
    const deleteArticleModalRef = useRef()

    const onSelectAction = (article, action) => {
        setSelectedArticle(article)
        setModalType(action)
    }

    return (
        <div className="flex flex-col gap-2 p-9 overflow-x-hidden">
            {/* Heading */}
            <div className="flex gap-4">
                <h1 className="mr-auto text-primary text-[1.75rem] font-ALSSchlangeslabBold">{localized('articles_title')}</h1>
                <Toggler
                    toggler={open => <Button color={Button.colors.SECONDARY} padding={Button.paddings.MEDIUM} label={localized('article_add')} target={open} />}
                    content={close =>
                        <Modal
                            ref={createArticleModalRef}
                            title={localized('article_add')}
                            closeHandler={close}
                            showCloseButton
                        >
                            <FormHandler
                                onSubmit={values =>
                                    createArticle(values)
                                        .then(createArticleModalRef.current.close)
                                        .then(() => toast.success(localized('toast_article_added')))
                                        .catch(() => toast.error(localized('toast_general_error')))}
                                type="add_article" />
                        </Modal>
                    }
                />
            </div>
            {/* <span className="text-primary mb-6">{ _.get(companies, 'pagination.total') }</span> */}

            {/* Content */}
            <LoadingWrapper isLoading={isLoading}>
                <Table
                    keyExtractor={e => e.id}
                    data={articles || []}
                    initialSelectedCol="title"
                    columns={[
                        { key: 'title', label: "Titel", isSortable: true },
                        { key: 'sub_title', label: "Subtitel", isSortable: false },
                        { key: 'label', label: "Label", isSortable: true },
                        {
                            key: 'actions', label: 'Acties', isSortable: false, format: (_value, item) => <TableActions actions={[
                                {
                                    name: 'edit',
                                    onClick: () => onSelectAction(item, 'edit'),
                                    render: <span className='cursor-pointer'>✏️</span>
                                },
                                {
                                    name: 'delete',
                                    onClick: () => onSelectAction(item, 'delete'),
                                    render: <span className='cursor-pointer'>🗑</span>
                                }
                            ]} />
                        }
                    ]}
                />
            </LoadingWrapper>

            {(!!selectedArticle && ReactDOM.createPortal(
                modalType === 'edit'
                    ? <Modal
                        ref={editArticleModalRef}
                        title={localized('article_edit')}
                        closeHandler={onSelectAction}
                        showCloseButton
                    >
                        <FormHandler
                            data={flattenArticle(selectedArticle)}
                            onSubmit={values =>
                                updateArticle(values, _.get(selectedArticle, 'id'))
                                    .then(editArticleModalRef.current.close)
                                    .then(() => toast.success(localized('toast_article_edited')))
                                    .catch(() => toast.error(localized('toast_general_error')))}
                            type="edit_article" />
                    </Modal>
                    : <Modal title={localized('article_delete')} ref={deleteArticleModalRef} closeHandler={setSelectedArticle}>
                    <div className="flex flex-col gap-5">
                        <span className="whitespace-pre-line">
                            {localized('article_delete_detail')}
                        </span>

                        <div className="flex justify-end gap-5">
                            <Button target={setSelectedArticle} label={localized('cancel')} color={Button.colors.PRIMARY_INVERSED} />
                            <Button target={() => {
                                deleteArticle(_.get(selectedArticle, 'id'))
                                    .then(deleteArticleModalRef.current.close)
                            }} label={localized('delete')} color={Button.colors.SECONDARY} isLoading={isLoading} />
                        </div>
                    </div>
            </Modal>,
                document.body
            ))}
        </div>
    )
}

export default Articles
