import _ from 'lodash'

const sizes = {
    mobileS: 375,
    mobileM: 414,
    mobileL: 567,
    tablet: 768,
    desktop: 960,
    large: 1280
}

const breakpoints = {
    mobileS: `(min-width: ${sizes.mobileS}px)`,
    mobileM: `(min-width: ${sizes.mobileM}px)`,
    mobileL: `(min-width: ${sizes.mobileL}px)`,
    tablet: `(min-width: ${sizes.tablet}px)`,
    desktop: `(min-width: ${sizes.desktop}px)`,
    large: `(min-width: ${sizes.large}px)`
}

const dateDistanceDivisions = [
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' }
]

const hardwareBreakpoints = {
    // hover: Filter devices with pointers that can hover
    // pointer: Filter devices with fine pointing devices (mouse, stylus)
    noTouch: `@media (hover: hover) and (pointer: fine)`,
    touch: `@media (hover: none) and (pointer: coarse)`
}

const getEnv = () => {
    const appEnv = process.env.REACT_APP_HOST_ENV
    return !appEnv || appEnv === 'test' ? 'dev' : appEnv
}

const getFullName = user => _.join(_.values(_.pick(user, ['firstName', 'lastName'])), ' ')

const getDateDistance = (date, showTime, fallback = 'Niet bekend') => {
    if (!date) return fallback

    date = new Date(date)
    const formatter = new Intl.RelativeTimeFormat('nl', { style: 'long', numeric: 'auto' })
    let duration = (date - new Date()) / 1000 / 60 / 60 / 24

    for (let i = 0; i <= dateDistanceDivisions.length; i++) {
        const division = dateDistanceDivisions[i]
        if (Math.abs(duration) < division.amount) {
            let result = formatter.format(Math.round(duration), division.name)

            return division.name === 'days' && showTime
                ? `${result} om ${new Intl.DateTimeFormat('nl', { timeStyle: 'short', hour12: false }).format(date)}`
                : result
        }
        duration /= division.amount
    }
}

const cleanObject = obj => {
    const cleanedObject = {}
    Object.keys(obj).forEach(key => obj[key] !== undefined && (cleanedObject[key] = obj[key]))
    return cleanedObject
}

const rangeToApex = (data, labels, filterType, filterValue, itemKey = 'status') => {
    const parsedData = [], pairedData = {}

    data?.forEach(item => {
        if (filterType && item?.[filterType] !== filterValue && itemKey === 'status') return

        if (pairedData[item?.[itemKey]]) {
            pairedData[item?.[itemKey]].push([item?.timestamp, +item?.count])
        } else {
            pairedData[item?.[itemKey]] = [[item?.timestamp, +item?.count]]
        }
    })
    Object.keys(pairedData).forEach(key => {
        if (labels[key] && pairedData[key]) {
            parsedData.push({ name: labels[key], data: pairedData[key] })
        }
    })

    return { data: parsedData }
}

const snapshotToApex = (data, labels, filterType, filterValue, itemKey = 'status') => {
    const parsedData = [], parsedLabels = []

    data.forEach(item => {
        if (filterType && item?.[filterType] !== filterValue && itemKey === 'status') return

        if (parsedLabels.includes(labels[item?.[itemKey]])) {
            const index = parsedLabels.indexOf(labels[item?.[itemKey]])
            parsedData[index] += +item?.count
        } else if (labels[item?.[itemKey]]) {
            parsedLabels.push(labels[item?.[itemKey]])
            parsedData.push(+item?.count)
        }
    })

    return { data: parsedData, labels: parsedLabels }
}

const getLastMonthDates = () => {
    const today = new Date()

    const startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()).toISOString().slice(0, 10)
    const endDate = today.toISOString().slice(0, 10)

    return { startDate, endDate }
}

const monthsDifference = (d1, d2) => {
    let months
    months = (d2.getFullYear() - d1.getFullYear()) * 12
    months -= d1.getMonth()
    months += d2.getMonth()
    return months <= 0 ? 0 : months
}

const toSnakeCase = obj => _.transform(obj, (acc, value, key, target) => {
    const snakeKey = _.isArray(target) ? key : _.snakeCase(key)
    acc[snakeKey] = _.isObject(value) ? toSnakeCase(value) : value
}, _.isArray(obj) ? [] : {})

const getFrequencyCount = (frequency, start, end) => {
    switch (frequency) {
        case 'day':
            return Math.ceil(Math.abs(Date.parse(start) - Date.parse(end)) / (1000 * 60 * 60 * 24))
        case 'week':
            return Math.ceil(Math.abs(Date.parse(start) - Date.parse(end)) / (1000 * 60 * 60 * 24 * 7))
        case 'month':
            return Math.ceil(Math.abs(Date.parse(start) - Date.parse(end)) / (1000 * 60 * 60 * 24 * 30))
        default:
            return Math.ceil(Math.abs(Date.parse(start) - Date.parse(end)) / (1000 * 60 * 60 * 24 * 30))
    }
}

export {
    sizes,
    breakpoints,
    hardwareBreakpoints,
    getEnv,
    getFullName,
    getDateDistance,
    cleanObject,
    rangeToApex,
    snapshotToApex,
    getLastMonthDates,
    monthsDifference,
    toSnakeCase,
    getFrequencyCount
}
