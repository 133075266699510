
import _ from "lodash"
import ReactApexChart from "react-apexcharts"

const LineChart = ({ data = [], height = 270, yLabel }) => {
    const getYAxisMax = () => {
        let highestValue

        data.forEach(item => {
            const arr = item.data
            const maxValue = Math.max(...arr.map(item => +item[1]))
            if (!highestValue || maxValue > highestValue) {
                highestValue = maxValue
            }
        })

        return highestValue * 1.2
    }

    const getYAxisMin = () => {
        let lowestValue

        data.forEach(item => {
            const arr = item.data
            const maxValue = Math.min(...arr.map(item => +item[1]))
            if (!lowestValue || maxValue < lowestValue) {
                lowestValue = maxValue
            }
        })

        return lowestValue * 0.8
    }

    const options = {
        chart: {
            type: 'line',
            height,
            foreColor: "#ccc",
            offsetX: -14,
            toolbar: {
              show: false
            },
            animations: {
                enabled: false
            },
          },
        colors: ['#6CD9e2', '#43A9CC', '#2C7BAF', '#26518A', '#262C5F'],
        stroke: {
            width: 1,
            curve: 'straight',
        },
        grid: {
            borderColor: "#F7F7F7",
            clipMarkers: false,
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        markers: {
            size: 1,
            colors: ["#6CD9e2"],
            strokeColor: "#6CD9e2",
            strokeWidth: 4
        },
        legend: {
            fontFamily: 'karla',
            offsetX: -26,
            offsetY: 24,
            markers: {
                radius: 4,
                width: 14,
                height: 14,
                offsetX: -4,
                offsetY: 0,
            },
            labels: {
                colors: '#4A4A4A',
                useSeriesColors: false
            },
            itemMargin: {
                vertical: 4
            },
        },
        series: data.map(item => ({ ...item, data: item.data?.map(([time, val]) => [time*1000, val])})),
        tooltip: {
            style: {
                fontSize: '12px',
                fontFamily: 'karla'
            },
        },
        xaxis: {
            tickPlacement: 'on',
            // Hardcoded based on location because i didn't feel like adding props to all the outer components :P
            tickAmount: window.location.pathname === '/analytics' ? 4 :  data[0] ? _.uniqBy(data[0].data, 0).length - 1 : 11,
            type: 'category',
            labels: {
                formatter: v => new Date(v).toLocaleDateString()
            }
        },
        yaxis: {
            min: getYAxisMin(),
            max: getYAxisMax(),
            tickAmount: 4,
            labels: {
                formatter: v => yLabel ? `${yLabel} ${v}` : v,
            },
            forceNiceScale: true
        },
    }

    return <ReactApexChart options={options} series={options.series} type={options.chart.type} height={options.chart.height} />

}

export default LineChart
