import { RadioSwitch } from "../components"
import { UserAverage, StatisticCard, Users, Surveys, EndedCases, CategoryAverage } from '../components/analytics'
import { caseLabels, coachUserStatusLabels, dataType, products, sort, userTypes } from "../config/constants"
import { routeTypes } from "../config/useRoutes"
import { generatePath, useNavigate } from "react-router"
import useSettings from "../utils/hooks/useSettings"
import { cleanObject } from "../utils/helper"
import useAnalytics, { interfaces } from "../utils/hooks/useAnalytics"
import { useEffect, useState } from "react"
import LoadingWrapper from "../components/generic/LoadingWrapper"

const defaultSettings = {
    // Query/Filter settings
    filterProduct: products.PLATFORM,
    filterUser: userTypes.CLIENT,

    // Sort settings for categories
    earningsSort: sort.ASC,
    expensesSort: sort.ASC,
    debtsSort: sort.ASC,

    // Settings for each queried block. Which dataType is selected
    [interfaces.BUDGET_STATS]: dataType.SNAPSHOT,
    [interfaces.QUESTIONAIRE]: dataType.SNAPSHOT,
    [interfaces.ONBOARDING]: dataType.SNAPSHOT,
    [interfaces.CASES]: dataType.SNAPSHOT,
    [interfaces.BUDGET_STATS]: dataType.SNAPSHOT,
    [interfaces.USER_STATUS]: dataType.SNAPSHOT,
}

// Which interfaces are part of each "tab". Used to determine which data to query on load
const pageInterfaces = {
    'platform-client': [[interfaces.USER_STATUS, { filters: [{ field: 'product_name', value: products.PLATFORM }] }], interfaces.BUDGET_STATS, interfaces.QUESTIONAIRE, /*interfaces.ONBOARDING*/],
    'platform-coach': [[interfaces.USER_STATUS, { filters: [{ field: 'product_name', value: products.PLATFORM }] }], [interfaces.PLATFORM_COLLABORATIONS, { dataType: dataType.SNAPSHOT }], interfaces.QUESTIONAIRE],
    [products.HUB]: [[interfaces.USER_STATUS, { filters: [{ field: 'product_name', value: products.HUB }] }], [interfaces.CASES, { dataType: dataType.SNAPSHOT }]],
}

// Helper function to get the props for components which share the same props.
const getProps = (set, nav, settings, getData, getLoading, inf, location, filters) => {
    const setting = settings[inf]

    return {
        data: getData(setting, inf, filters && { filters }),
        loading: getLoading(setting, inf, filters && { filters }),
        setting: setting,
        settings,
        set: v => set(inf, v),
        onDetailClick: () => nav(location)
    }
}

// Helper function to get a stat from the data object based on the interface and key
const getStat = (getData, inf, key) => getData(dataType.SNAPSHOT, inf)?.[0]?.[key]

const PlatformClientGrid = ({ getData, getLoading, settings, set, navigate }) => {
    const props = (inf, location, filters) => getProps(set, navigate, settings, getData, getLoading, inf, location, filters)

    return <>
        <div className="flex flex-col gap-8">
            <Users title="Cliënten" userType={userTypes.CLIENT} {...props(interfaces.USER_STATUS, 'users', [{ field: 'product_name', value: products.PLATFORM }])} />
            <UserAverage {...props(interfaces.BUDGET_STATS, 'platformClientUserAverage')} />
        </div>
        <div className="flex flex-col gap-8">
            <Surveys title="Vragenlijsten financiële stressmeting" userType={userTypes.CLIENT} {...props(interfaces.QUESTIONAIRE, 'stressForm')}/>
            {/* <div className="grid grid-cols-3 gap-8 h-fit">
                <StatisticCard title="Notities" number="78" icon="note" detailRoute="platformData" onClick={() => navigate('platformNotesAndUploads')} />
                <StatisticCard title="Uploads" number="32" icon="upload" detailRoute="platformData" onClick={() => navigate('platformNotesAndUploads')} />
                <StatisticCard title="Afgeronde acties" number="157" icon="action-completed" detailRoute="platformData" onClick={() => navigate('platformActions')} />
            </div> */}
            {/* <ClientOnboarding title="Stappenplan" legendTitle="Gebruikers met" {...props(interfaces.ONBOARDING, 'platformClientOnboarding')}/> */}
        </div>
        <div className="col-span-2">
            <CategoryAverage 
                earningsSort={settings.earningsSort}
                setEarningsSort={v => set('earningsSort', v)}
                expensesSort={settings.expensesSort}
                setExpensesSort={v => set('expensesSort', v)}
                debtsSort={settings.debtsSort}
                setDebtsSort={v => set('debtsSort', v)}
                data={getData(dataType.SNAPSHOT, interfaces.BUDGET_AVERAGES)}
                loading={getLoading(dataType.SNAPSHOT, interfaces.BUDGET_AVERAGES)}
            />
        </div>
    </>
}

const PlatformCoachGrid = ({ getData, getLoading, settings, set, navigate }) => {
    const props = (inf, location, filters) => getProps(set, navigate, settings, getData, getLoading, inf, location, filters)

    return <>
        <div className="flex flex-col gap-8">
            <Users labels={coachUserStatusLabels} title="Coaches" userType={userTypes.COACH} product={settings.filterProduct} {...props(interfaces.USER_STATUS, 'users', [{ field: 'product_name', value: products.PLATFORM }])} />
            {/* <ActiveCoaches items={data.dummyActiveCoaches} /> */}
            <div className="grid grid-cols-3 gap-8 h-fit">
                {/* <StatisticCard title="Aangemaakte acties" number="132" icon="action" onClick={() => navigate('platformActions')} /> */}
                <StatisticCard title="Aantal cliënten per coach" number={getStat(getData, interfaces.PLATFORM_COLLABORATIONS, 'coach_client_avg_count')} icon="client" onClick={() => navigate('platformCollaborations')} />
                <StatisticCard title="Aantal cliënten per organisatie" number={getStat(getData, interfaces.PLATFORM_COLLABORATIONS, 'org_client_avg_count')} icon="coach" onClick={() => navigate('platformCollaborations')} />
            </div>
        </div>
        <div className="flex flex-col gap-8">
            <Surveys title="Vragenlijsten financiële stressmeting" userType={userTypes.COACH} {...props(interfaces.QUESTIONAIRE, 'stressForm')} />
        </div>
    </>
}

const HubGrid = ({ getData, getLoading, settings, set, navigate }) => {
    const props = (setting, inf, location, filters) => getProps(set, navigate, settings, getData, getLoading, setting, inf, location, filters)

    return  <>
        <div className="flex flex-col gap-8">
            <Users title="Geactiveerde gebruikers" {...props(interfaces.USER_STATUS, 'users', [{ field: 'product_name', value: products.HUB }])} />
            <EndedCases {...props(interfaces.CASES, 'hubEndedCases')} />
        </div>
        <div className="grid grid-cols-3 gap-8 h-fit">
            <StatisticCard title={caseLabels.project_active_count} number={getStat(getData, interfaces.CASES, 'project_active_count')} icon="cases" onClick={() => navigate('hubCases')} />
            <StatisticCard title={caseLabels.project_pending_count} number={getStat(getData, interfaces.CASES, 'project_pending_count')} icon="upcoming-cases" onClick={() => navigate('hubCases')} />
            <StatisticCard title={caseLabels.project_closed_count} number={getStat(getData, interfaces.CASES, 'project_closed_count')} icon="closed-cases" onClick={() => navigate('hubCases')} />
            <StatisticCard title={caseLabels.project_completed_task_completed_avg_count} number={getStat(getData, interfaces.CASES, 'project_completed_task_completed_avg_count')} icon="tasks-completed" onClick={() => navigate('hubCaseTasks')} />
            <StatisticCard title={caseLabels.project_midphase_cancelled_task_completed_avg_count} number={getStat(getData, interfaces.CASES, 'project_midphase_cancelled_task_completed_avg_count')} icon="tasks-cancelled" onClick={() => navigate('hubCaseTasks')} />
        </div>
    </>
}

const Analytics = () => {
    const navigate = useNavigate()
    const [, { fetchData, getData, getLoading }] = useAnalytics()
    const [settings, setSettings] = useSettings(defaultSettings)
    const [isPageLoading, setIsPageLoading] = useState(true)

    const { filterProduct, filterUser } = settings

    const set = (key, value) => setSettings({ ...settings, [key]: value })

    const handleNavigate = (route, params) => {
        const path = generatePath(routeTypes.ANALYTIC_DETAIL_PATH, { id: route })
        const joinedParams = { filterProduct, filterUser, ...params }
        const searchParams = new URLSearchParams(cleanObject(joinedParams)).toString()
        navigate(`${path}?${searchParams}`)
    }

    useEffect(() => {
        // Get needed data for the page
        const doesDataExist = (type = dataType.SNAPSHOT, inf, body) => getData(type, inf, body).length > 0 || getLoading(type, inf, body)

        const page = filterProduct === products.PLATFORM ? `${products.PLATFORM}-${filterUser}` : filterProduct
        const pageInterface = pageInterfaces[page]

        // Define default start date for range data
        const startDate = new Date()
        startDate.setFullYear(startDate.getFullYear() - 1)

        // Flatmap array of promises for queried data based on interfaces for current "tab"
        const apiCalls = pageInterface.flatMap(inf => {

            // If interface is an array, Then it may need multiple queries for the same interface.
            if (Array.isArray(inf)) {
                // Get unique types for the page interfaces, (in case of both range and snapshot data this will return both, Else it will return only one interface since it's unique)
                let types = []

                if (inf[1]?.dataType) {
                    if (settings[inf[0]] === inf[1]?.dataType) {
                        types.push(inf)
                    } else {
                        types.push(inf)
                        types.push([inf[0], { ...inf[1], dataType: settings[inf[0]] }])
                    }
                } else {
                    types = [[inf[0], { ...inf[1], dataType: settings[inf[0]] }]]
                }
                // const types = [...new Set([settings[inf[0]] || dataType.SNAPSHOT, inf[1]])]

                // If data already exists (or is currently loading) (or is USER_STATUS interface), return empty array to prevent duplicate api calls, Else return promise. Flatmap this for (potentially) both interfaces
                return types.flatMap(([subInf, { dataType: type, filters }]) => {
                    const exists = doesDataExist(type, subInf, { filters })
                    return exists ? [] : fetchData(subInf, type === dataType.RANGE ? { startDate: startDate.toISOString(), filters } : { filters })
                })
            } else {
                // If data already exists (or is currently loading) (or is USER_STATUS interface), return empty array to prevent duplicate api calls, Else return promise
                const exists = doesDataExist(settings[inf], inf)
                return exists ? [] : fetchData(inf, settings[inf] === dataType.RANGE ? { startDate: startDate.toISOString() } : {})
            }
        })

        // If flatmap contains promises. Set page loading to true and wait for all promises to resolve
        if (apiCalls.length > 1) {
            setIsPageLoading(true)
            Promise.all(apiCalls).finally(() => setIsPageLoading(false))
        } else {
            setIsPageLoading(false)
        }

        // Query budget averages seperate from page loading state because it takes a while.
        if (page === `${products.PLATFORM}-client` && !doesDataExist(dataType.SNAPSHOT, interfaces.BUDGET_AVERAGES)) {
            fetchData(interfaces.BUDGET_AVERAGES)
        }
    }, [settings]) // eslint-disable-line

    return (
        <div className="flex flex-col gap-8 p-9 pb-[5vh] max-w-[1372px] mx-auto text-primary">
            <h1 className="mr-auto text-primary text-[1.75rem] font-ALSSchlangeslabBold">Analytics</h1>

            <div className="flex gap-4 -mt-2 h-fit">
                <RadioSwitch
                    selected={filterProduct}
                    setSelected={v => set('filterProduct', v)}
                    items={[{ label: 'Platform', value: products.PLATFORM }, { label: 'Hub', value: products.HUB }]}
                />

                {filterProduct === products.PLATFORM && 
                    <RadioSwitch
                        selected={filterUser}
                        setSelected={v => set('filterUser', v)}
                        items={[{ label: 'Cliënt', value: userTypes.CLIENT }, { label: 'Coach', value: userTypes.COACH }]}
                    />
                }
            </div>

            <LoadingWrapper containerClass="grid grid-cols-2 gap-8 h-fit" isLoading={isPageLoading}>
                {filterProduct === products.PLATFORM && filterUser === userTypes.CLIENT &&
                    <PlatformClientGrid 
                        getLoading={getLoading}
                        getData={getData} 
                        settings={settings} 
                        set={set} 
                        navigate={handleNavigate} 
                    />
                }
                {filterProduct === products.PLATFORM && filterUser === userTypes.COACH &&
                    <PlatformCoachGrid
                        getLoading={getLoading}
                        getData={getData} 
                        settings={settings}
                        set={set}
                        navigate={handleNavigate}
                    />
                }
                {filterProduct === products.HUB &&
                    <HubGrid
                        getLoading={getLoading}
                        getData={getData}
                        settings={settings}
                        set={set}
                        navigate={handleNavigate}
                    />
                }
            </LoadingWrapper>
        </div>
    )
}

export default Analytics


// ### UNUSED ORGANISATION AND MUNICIPALITY FILTERS ###

// ### Icons
// const OrganisationIcon = () => <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1.5" stroke="currentColor" className="h-5" viewBox="2.25 2.25 19.5 19.5"><path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"></path></svg>
// const MunicipalityIcon = () => <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1.5" stroke="currentColor" className="h-5" viewBox="3.75 2.25 16.5 20.25"><path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"></path><path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"></path></svg>

// ### Page functions
// const parseConcatParams = val => {
//     if (!val) return
//     const splitVal = val.split('_')
//     if (splitVal.length === 2) return { value: splitVal[0], label: splitVal[1] }
// }

// const dummySearch = async () => {
//     await new Promise(resolve => setTimeout(() => resolve(), 1000))
//     return [{ label: 'Option 1', value: '1' }, { label: 'Option 2', value: '2' }, { label: 'Option 3', value: '3' }, { label: 'Option 4', value: '4' }]
// }

// ### Page content (interactables)
// <SearchPopover value={parseConcatParams(organisation)} onSearch={dummySearch} onChange={({ value, label }) => set('organisation',`${value}_${label}`)} icon={<OrganisationIcon/>} label="Selecteer organisatie" placeholder="Zoek organisatie"  />
// <SearchPopover value={parseConcatParams(municipality)} onSearch={dummySearch} onChange={({ value, label }) => set('municipality',`${value}_${label}`)} icon={<MunicipalityIcon/>} label="Selecteer gemeente" placeholder="Zoek gemeente" />
