import _ from "lodash";
import { Formik } from 'formik'
import { FormItem } from './FormElements'
import { useLocalized } from '../../config/localized-context'
import { useForms } from '../../config/forms'
import { Button } from "@plinkrlabs/plinkr-ui";

const parseNestedValues = values => _.reduce(_.keys(values), (prev, next) => _.set(prev, next, _.get(values, next)), {})

const FormHandler = ({ data = {}, onSubmit, type, buttonLabel = 'Wijzigingen opslaan', selectOptions }) => {
    const [validator, fields] = useForms(type, selectOptions)
    const { localized } = useLocalized()

    const getValue = ({ name, type, defaultValue, options, props }) => {
        let value = _.get(data, name) || defaultValue

        if (!value) {
            switch(type) {
                case 'text':
                default:
                    value = ''
                    break
                case 'number':
                    value = undefined
                    break
                case 'select':
                    value = _.get(_.head(options), 'value')
                    break
                case 'checkbox':
                    value = false
                    break
                case 'multiSelect':
                    value = props?.isUnique ? JSON.stringify(options[0]) : '[]'
                    break
                case 'multiInput':
                    value = []
                    break
            }
        }

        return { [name]: value }
    }

    return (
        <Formik
            initialValues={fields.reduce((acc, curr) => ({ ...acc, ...getValue(curr) }), getValue(fields[0]))}
            validationSchema={validator}
            onSubmit={(values, { setSubmitting }) => onSubmit(parseNestedValues(values)).then(() => setSubmitting(false))}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
            }) =>
                <div>
                    {fields.map(item =>
                        <FormItem
                            key={_.get(item, 'name')}
                            type={_.get(item, 'type')}
                            name={_.get(item, 'name')}
                            props={_.get(item, 'props', {})}
                            label={localized(_.get(item, 'label'))}
                            options={_.get(item, 'options')}
                            onChange={handleChange}
                            optional={_.get(item, 'optional')}
                            hasDivider={_.get(item, 'hasDivider')}
                            value={_.get(values, _.get(item, 'name'))}
                            error={_.get(touched, _.get(item, 'name')) && _.get(errors, _.get(item, 'name'))}
                        />
                    )}
                    <div className="flex justify-end">
                        <Button
                            isLoading={isSubmitting}
                            color={Button.colors.SECONDARY}
                            label={buttonLabel}
                            target={handleSubmit}
                        />
                    </div>
                </div>
            }
        </Formik>
    )
}

export default FormHandler
