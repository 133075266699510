import { sort } from "../../config/constants"
import AnalyticsCard from "../AnalyticsCard"
import LoadingWrapper from "../generic/LoadingWrapper"
import categories from "../../config/categories.json"
import { getSubcategory } from "../../config/content"
import { generatePath, useNavigate } from "react-router-dom"
import { routeTypes } from "../../config/useRoutes"

const getColSum = col => col.reduce((sum, { avg_sum }) => sum += +avg_sum, 0)

const Label = ({ amount = 0 }) => <>
    <span className="font-bold">€</span>
    <span className="font-bold block text-right min-w-[70px]">{parseFloat(amount / 100).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span>
</>

const OuterCategory = ({ title, sortType, setIsAscending, data, loading, onSubcategoryClick, showLoader }) =>
    <AnalyticsCard 
        title={title}
        padding="0" 
        hideToggle 
        showSort 
        isAscending={sortType === sort.ASC} 
        setIsAscending={setIsAscending}
    >
        <LoadingWrapper loaderProps={{ isHidden: !showLoader }} containerClass="flex flex-col gap-4 overflow-y-scroll box-content h-[65vh] py-6" isLoading={loading}>
            {Object.entries(data).sort(([,a], [,b]) => sortType === sort.ASC ? getColSum(a) - getColSum(b) : getColSum(b) - getColSum(a)).map(([category, column]) =>
                <InnerCategory onSubcategoryClick={onSubcategoryClick} key={category} data={column} title={categories[`category_${category}`]} total={getColSum(column)} />
            )}  
        </LoadingWrapper>
    </AnalyticsCard>

const InnerCategory = ({ title, data = [], total, onSubcategoryClick }) =>
    <div
        className="
            relative border border-neutral-60 rounded-md h-fit
            pl-2 mr-6
        "
    >
        <div className="grid items-center gap-2 ml-4 pr-6 grid-cols-[1fr_auto_auto] h-[56px] border-b border-neutral-60">
            <h1 className="first-letter:capitalize">{ title }</h1>
            <Label amount={total} />
        </div>
        <div className="flex flex-col py-2 pr-2 text-sm">
            {data.sort((a, b) => b.avg_sum - a.avg_sum).map(item => <div 
                key={item.category_sub} 
                className="
                    flex justify-between gap-2 
                    px-4 py-2 rounded-md 
                    transition-colors cursor-pointer hover:bg-primary-100
                "
                onClick={() => onSubcategoryClick(item.category_sub)}
            >
                <span className="w-full first-letter:capitalize">{categories[`subcategory_${getSubcategory(item.category_sub)}`]}</span>
                <Label amount={item.avg_sum} />
            </div>)}
        </div>
    </div>

const CategoryAverage = ({ earningsSort, setEarningsSort, expensesSort, setExpensesSort, debtsSort, setDebtsSort, data = [], loading }) => {
    const navigate = useNavigate()
    const datasets = { earnings: {}, expenses: {}, debts: {} }

    data.forEach(({ budget_type, category_main, ...rest }) => {
        if (budget_type === "debts") {
            if (!datasets[budget_type][budget_type]) datasets[budget_type][budget_type] = []
            datasets[budget_type][budget_type].push({ ...rest })
        } else {
            if (!datasets[budget_type][category_main]) datasets[budget_type][category_main] = []
            datasets[budget_type][category_main].push({ ...rest })
        }
    })

    const onSubcategoryClick = id => {
        const path = generatePath(routeTypes.ANALYTIC_DETAIL_PATH, { id: 'subcategory' })
        navigate(`${path}?id=${id}`)
    }

    return (
        <div className="mt-14">
            <h1>Gemiddeldes per budget categorie</h1>
            <div className="grid grid-cols-3 gap-8 mt-8">
                <OuterCategory 
                    title="Inkomen" 
                    data={datasets.earnings} 
                    sortType={earningsSort} 
                    setIsAscending={() => setEarningsSort(earningsSort === sort.ASC ? sort.DESC : sort.ASC)}
                    loading={loading}
                    onSubcategoryClick={onSubcategoryClick}
                />
                <OuterCategory 
                    title="Uitgaven" 
                    data={datasets.expenses} 
                    sortType={expensesSort} 
                    setIsAscending={() => setExpensesSort(expensesSort === sort.ASC ? sort.DESC : sort.ASC)}
                    loading={loading}
                    onSubcategoryClick={onSubcategoryClick}
                    showLoader
                />
                <OuterCategory 
                    title="Schulden" 
                    data={datasets.debts}  
                    sortType={debtsSort} 
                    setIsAscending={() => setDebtsSort(debtsSort === sort.ASC ? sort.DESC : sort.ASC)}
                    loading={loading}
                    onSubcategoryClick={onSubcategoryClick}
                />
            </div>
        </div>
    )
}

export default CategoryAverage
