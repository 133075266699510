import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { ContentContext } from '../ContentContext'

const parseCompanyData = company => ({
    ..._.pick(company, ['name', 'website', 'email', 'phone', 'alternative_names']),
    ...(_.get(company, 'street') && {
        address: {
            ..._.pick(company, ['number', 'mail_box', 'city', 'street', 'postal', 'addition']),
        }
    })
})

const useCompanies = () => {
    const companyContext = useContext(ContentContext)
    const [isLoading, setIsLoading] = useState(false)

    if (companyContext === undefined) {
        throw new Error(`useData hook must be used within a CompanyProvider`)
    }

    const { companies, company, dispatch, apiCall } = companyContext
    const { sort_by, sort_order, search } = _.get(companies, 'filter', {})
    const { limit, offset, total } = _.get(companies, 'pagination', {})

    const getCompanies = async (params) => {
        setIsLoading(true)

        try {
            const { data } = await apiCall('/companies', { params }, 'get', true)

            if (!_.isEmpty(data)) {
                dispatch({
                    companies: data,
                    type: 'COMPANY_LIST_FETCH_SUCCESS',
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    const getCompany = async companyId => {
        setIsLoading(true)
        try {
            const { data } = await apiCall(`/companies/${companyId}`, {}, 'get', true)

            if (data) {
                dispatch({
                    company: parseCompanyData(data),
                    type: 'COMPANY_FETCH_SUCCESS',
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    const createCompany = async company => {
        setIsLoading(true)
        try {
            const newCompany = parseCompanyData(company)
            const { data } = await apiCall('/companies', {
                body: newCompany
            }, 'post', true)

            if (_.get(data, 'jd')) {
                dispatch({
                    company: {
                        id: _.get(data, 'jd'),
                        ...newCompany
                    },
                    type: 'COMPANY_CREATE_SUCCESS',
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    const updateCompany = async (company, companyId) => {
        setIsLoading(true)
        try {
            await apiCall(
                `/companies/${companyId}`,
                { body: parseCompanyData(company) },
                'put',
                true
            )

            dispatch({
                company: {
                    ...company,
                    id: companyId
                },
                type: 'COMPANY_UPDATE_SUCCESS',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const deleteCompany = async companyId => {
        setIsLoading(true)
        try {
            await apiCall(`/companies/${companyId}`, {}, 'delete', true)

            dispatch({
                companyId,
                type: 'COMPANY_DELETE_SUCCESS',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const setFilter = values => {
        dispatch({
            filter: values,
            type: 'SET_COMPANY_FILTER',
        })
    }

    const setPagination = values => {
        dispatch({
            key: 'companies',
            offset: _.get(values, 'offset', 0),
            type: 'SET_PAGINATION',
        })
    }

    useEffect(() => {
        getCompanies({ limit, offset, total, ..._.size(search) ? { search } : { sort_by, sort_order } })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort_by, sort_order, search, limit, offset, total])

    return {
        getCompanies,
        getCompany,
        createCompany,
        updateCompany,
        deleteCompany,
        setFilter,
        setPagination,

        companies,
        company,
        filter: { sort_by, sort_order, search },
        pagination: { limit, offset, total },

        isLoading,
    }
}

export default useCompanies
